import React, { useEffect, useState } from 'react';
import './App.scss';
import '../variables.scss'
import Layout from './Layout';
import { NavBar} from "./navbar/NavBar";
import MeetingDetails from "./meeting/MeetingDetails";
import Meeting from "./meeting/MeetingForm";
import Contacts from "./contacts/Contacts";
import Toast from "./common/Toast";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { PuffLoader } from "react-spinners";
import { UserData } from "../components/Types"
import { Login } from './auth/Login';
import { Home } from "./home/HomePage";
import { ScrollToTop } from "./ScrollToTop";
import { SuccessIcon, ErrorIcon} from "./common/Icons";
import { verifyUser } from "../api/user";
import {Register} from "./auth/Register";
import {Reset} from "./auth/Reset";
import {Password} from "./auth/Password";
import { t } from "../languages/Translations";
import {SPINNER_CSS} from "./Constant";
import {currentToken} from "./Utils";

let userContext:UserData = {
    token: '',
    email: '',
    pwdchange: false,
    cities: [],
    contacts: [],
    rooms: [],
    member: {
        nameFirst: '',
        nameLast: '',
        email: '',
        code: '',
        badge: ''
    },
    displaybadge: false
};


const App = () => {
    const [toastData, setToast] = useState({ display: false, error: false, title: '', message: '', color: '', timer: 5000 });
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [loading, setLoading] = useState(false);
    const [displayBadge, setDisplayBadge] = useState(false);
    const [color] = useState("#f35c2c");

    document.addEventListener('mousedown', function(e) {
        if(userContext && userContext.displaybadge) setDisplayBadge(false)
    });
    // verify token is valid
    useEffect(() => {
        if(window.location.pathname !== "/reset") {
            if (currentToken() != null) {
                verifyUser()
                    .then((res) => {
                        userContext.pwdchange = res.credentialUpdateRequired;
                        userContext.member = res.member;
                        setLoggedIn(true)
                    }).catch(e => {
                    displayToaster({

                        message: t('common.verify_failed'),
                        error: true
                    })
                })
            } else setLoggedIn(false);
        }
        else {
            setLoggedIn(false);
            userContext.token = null;
            userContext.email = null;
        }
    }, []);

    function displayToaster(data) {
        data.display = true;
        if(!data.timer) data.timer = 5000
        setToast(data);
        setTimeout(() => {
            data.display = false
            setToast(data);
        }, data.timer);
    }

    return (
        <Router>
            {toastData.display ? <Toast
                toastData={[{
                    id: 1,
                    title: toastData.error ? t('common.toast.error_title') : t('common.toast.success_title'),
                    message: toastData.message ? toastData.message : t('common.toast.processed_message'),
                    titleColor: toastData.color ? toastData.color : '#a9c274',
                    icon: toastData.error ? <ErrorIcon/> : <SuccessIcon />
                }]}
                autoDelete={true}
                autoDeleteTime={toastData.timer}
            /> : null}
            <NavBar value={{ isLoggedIn, setLoading, displayBadge, setDisplayBadge }} />
                <ScrollToTop/>
                    <Switch>
                        <Route exact path="/reset" render={() =>
                            <Layout><Reset value={{ setLoading, displayToaster }}/></Layout>
                        }/>
                        <Route exact path="/login" render={() =>
                            isLoggedIn && !userContext.pwdchange
                                ? (<Layout><Home value={{setLoading, loading, displayToaster }}/></Layout>)
                                : !userContext.pwdchange
                                    ? (<Layout><Login value={{setLoading, setLoggedIn }}/></Layout>)
                                    : (<Layout><Password value={{setLoading, displayToaster, setLoggedIn}}/></Layout>)
                        }/>
                        <Route exact path="/register" render={() =>
                            <Layout><Register value={{ setLoading, displayToaster }}/></Layout>
                        }/>
                        <Route exact path="/password" render={() =>
                            <Layout><Password value={{setLoading, displayToaster, setLoggedIn}}/></Layout>
                        }/>
                        <Route exact path="/" render={() =>
                            isLoggedIn && !userContext.pwdchange
                                ? (<Layout><Home value={{setLoading, loading, displayToaster }}/></Layout>)
                                : !userContext.pwdchange
                                    ? (<Layout><Login value={{setLoading, setLoggedIn }}/></Layout>)
                                    : (<Layout><Password value={{setLoading, displayToaster, setLoggedIn}}/></Layout>)
                        }/>
                        <Route exact path="/home" render={() =>
                            isLoggedIn && !userContext.pwdchange
                                ? (<Layout><Home value={{setLoading, loading, displayToaster }}/></Layout>)
                                : !userContext.pwdchange
                                    ? (<Layout><Login value={{setLoading, setLoggedIn }}/></Layout>)
                                    : (<Layout><Password value={{setLoading, displayToaster, setLoggedIn}}/></Layout>)
                        }/>
                        <Route exact path="/meeting" render={props => (
                            isLoggedIn && !userContext.pwdchange
                                ? (<Layout styleClasses="meeting-form-content"><Meeting value={{setLoading, displayToaster}}/></Layout>)
                                : !userContext.pwdchange
                                    ? (<Layout><Login value={{setLoading, setLoggedIn }}/></Layout>)
                                    : (<Layout><Password value={{setLoading, displayToaster, setLoggedIn}}/></Layout>)
                        )}/>
                        <Route exact path="/meeting/details" render={props => (
                            isLoggedIn && !userContext.pwdchange
                                ? (<Layout styleClasses="meeting-form-content"> <MeetingDetails {...props} setLoading={setLoading} displayToaster={displayToaster}/></Layout>)
                                : !userContext.pwdchange
                                    ? (<Layout><Login value={{setLoading, setLoggedIn }}/></Layout>)
                                    : (<Layout><Password value={{setLoading, displayToaster, setLoggedIn}}/></Layout>)
                        )}/>
                        <Route exact path="/contacts" render={props => (
                            isLoggedIn && !userContext.pwdchange
                                ? (<Layout><Contacts value={{setLoading, displayToaster }}/></Layout>)
                                : !userContext.pwdchange
                                    ? (<Layout><Login value={{setLoading, setLoggedIn }}/></Layout>)
                                    : (<Layout><Password value={{setLoading, displayToaster, setLoggedIn}}/></Layout>)
                        )}/>
                    </Switch>
                    <PuffLoader color={color} loading={loading} size={150} css={SPINNER_CSS}/>

        </Router>
    );
}

export default App;
export { userContext };