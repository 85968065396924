import React from 'react';
import { useField } from 'formik';
import { WarningIcon } from '../Icons';
import './FATextField.scss';

//Textfield with a label, an input and an error message
const FATextField2 = ({ simple, label, icon, handleChange, autocomplete, required, readonly, size, autofocus, ...props }: any) => {
    const [field, meta] = useField(props);

    return (
        <div className="fatextfield">
            <div className="flex">
                <label>{label}&nbsp;{required ? <b>*</b> : ""}</label>{meta.touched && meta.error ? (<span className="error-label">{meta.error}</span>) : null}
            </div>
            {handleChange ?
                <input {...field} {...props} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)} className={meta.touched && meta.error ? 'error' : '' } autoComplete={ !autocomplete ? "off" : "on"} foc  autoFocus={autofocus}  />
                :
                <input {...field} {...props} className={meta.touched && meta.error ? 'error' : ''} autoComplete={ !autocomplete ? "off" : "on"} autoFocus={autofocus} />
            }
            <div className="icon-container">{meta.touched && meta.error ? (<WarningIcon value="orange" />) : icon}</div>
        </div>
    );
}

const FASimpleTextField2 = ({ label, icon, handleChange, autocomplete, readonly, autofocus, ...props }: any) => {
    return (
        <div className="fatextfield">
            <div className="flex">
                <label>{label}</label>
            </div>
            {handleChange ?
                <input {...props} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)} autoComplete={ !autocomplete ? "off" : "on"} autoFocus={autofocus}/>
                :
                <input {...props} autoComplete={ !autocomplete ? "off" : "on"} autoFocus={autofocus}/>
            }
            <div className="icon-container">{icon}</div>
        </div>
    )
}

export { FATextField2, FASimpleTextField2 };
