import React from "react";
import './Badge.scss';
import { t } from '../../languages/Translations'
import {Link} from "react-router-dom";

const Badge = (props) => {

    return (
        <div className="modal-badge" onClick={props.handleClose} id={"badge"}>
            <div className="modal-badge-header">{t("common.nav_bar.badge_title")}</div>
            <div className="modal-badge-header-info">{t("common.nav_bar.badge_title_info")}</div>
            <div className={`modal-badge-body`}>
                <img src={"data:image/png;base64,"+props.data} height={200} width={200} onClick={props.handleClose}/>
            </div>
            <div className='modal-badge-footer'><Link onClick={props.handleClose} to={""}>{t('common.close')}</Link></div>
        </div>
    );
}

export  { Badge };
