import { currentToken } from "../components/Utils";
import {AuthData, PasswordData, RegisterFormData, ResetData} from "../components/Types";

// -------------------------------------------------------------------
/**
 *
 * @param credentials
 */
export async function loginUser(credentials: AuthData) {
  const options:RequestInit = {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      "identity": credentials.email,
      "credential": credentials.password,
      "product": 2,
      "captcha": credentials.captcha
    })
  }
  // ------------
  return fetch(`${process.env.REACT_APP_PUBLIC_URL}/member/102/account/logon`, options).then(async (r) => {
    if(r.status !== 200) throw "http:"+r.status;
    let json = await r.json()
    if(json.header.status === 0) throw json.header.error;
    return json;
  });
}

// ------------------------------------------------------------------
/**
 *
 */
export async function logoutUser() {
  const options:RequestInit = {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ currentToken()
    },
    body: JSON.stringify({
      "token": currentToken()
    })
  }
  // ------------------------------
  return await fetch(`${process.env.REACT_APP_PUBLIC_URL}/member/102/account/logout`, options).then(async (r) => {
    if(r.status !== 200) throw "http:"+r.status;
    let json = await r.json()
    if(json.header.status === 0) throw json.header.error;
    return json;
  });
}

// ------------------------------------------------------------------
/**
 *
 */
export async function verifyUser() {
  const token = currentToken();
  if(token === null || token.length === 0) throw "9999";

  const options:RequestInit = {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ currentToken()
    },
    body: JSON.stringify({
      "token": currentToken()
    })
  }
  // ------------------------------
  return await fetch(`${process.env.REACT_APP_PUBLIC_URL}/member/102/account/verify`, options).then(async (r) => {
    if(r.status !== 200) throw "http:"+r.status;
    let json = await r.json()
    if(json.header.status === 0) throw json.header.error;
    return json;
  });
}

// ------------------------------------------------------------------
/**
 *
 */
export async function registerUser(data: RegisterFormData) {
  const options:RequestInit = {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      "email": data.email,
      "identity": data.email,
      "code": data.codeA+"-"+data.codeB,
      "nameFirst": data.firstname,
      "nameLast": data.lastname,
      "captcha": data.captcha
    })
  }
  // ------------
  return fetch(`${process.env.REACT_APP_PUBLIC_URL}/member/102/account/register`, options).then(async (r) => {
    if(r.status !== 200) throw "http:"+r.status;
    let json = await r.json()
    if(json.header.status === 0) throw json.header.error;
    return json;
  });
}

// ------------------------------------------------------------------
/**
 *
 */
export async function resetPassword(data: ResetData) {
  const options:RequestInit = {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      "email": data.email,
      "identity": data.email,
      "captcha": data.captcha
    })
  }
  return fetch(`${process.env.REACT_APP_PUBLIC_URL}/member/102/account/password/reset`, options).then(async (r) => {
    if(r.status !== 200) throw "http:"+r.status;
    let json = await r.json()
    if(json.header.status === 0) throw json.header.error;
    return json;
  });
}
// ------------------------------------------------------------------
/**
 *
 */
export async function changePassword(data: PasswordData) {
  const options:RequestInit = {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      "email": data.email,
      "identity": data.email,
      "token": data.key,
      "credential_new": data.password,
    })
  }
  // ------------
  return fetch(`${process.env.REACT_APP_PUBLIC_URL}/member/102/account/password`, options).then(async (r) => {
    if(r.status !== 200)  throw "http:"+r.status;
    let json = await r.json()
    if(json.header.status === 0) throw json.header.error;
    return json;
  });
}
/**
 *
 * @param data
 */


