import React from 'react'

const Layout = (props: any) => {
  const styleClasses= props.styleClasses;
  return (
    <div className="page">
      <div className="desktop-only image-placeholder"/>
      <div className={styleClasses ? styleClasses + " content" : "content"} id="content">
        {props.children}
      </div>
    </div>
  )
}

export default Layout;