import React from 'react';
import './FAButtons.scss';

interface IFAButton {
    size?: string,
    color?: string,
    className?: string,
    icon?: any,
    label?: string
    handleClick: () => void,
    type?: any,
    disabled?: any,
    height?: any,
    width?: any
}

const SquareButton: React.FC<IFAButton> = (props) => {
    const className = 'icon-button ' + props.size + '-square ' + props.className + ' ' + props.color;
    return (
        <button type="button" className={className} onClick={props.handleClick}>
            {props.icon}
        </button>
    );
}

const BigButton: React.FC<IFAButton> = ({ label, icon, size, color, className, handleClick, ...props }) => {
    let style = {};
    //Fix to display the button correctly regarding the screen size. TODO: To improve with update in P3
    if (props.width && props.height) {
        let right = props.width / 2 - 100;
        if (props.width >= 768) {
            right = 50;
        }
        style = {
            position: 'fixed',
            top: props.height - 100,
            right: right,
            'boxShadow': '2px 2px 3px #999'
        }
    }

    return (
        <button style={style} className={"big-button"} onClick={handleClick} {...props}><div>{label}</div><div className="icon">{icon}</div></button>
    );
}

export { SquareButton, BigButton };