import React from 'react';
import { useField } from 'formik';
import { WarningIcon } from '../Icons';
import './FASelectField.scss';

//Textfield with a label, an input and an error message
const FASelectField = ({ label, icon, values, defaultValue, enabled, ...props }: any) => {
    const [field, meta] = useField(props);

    return (
        <div className="fatextfield">
            <div className="flex">
                <label>{label}</label>{meta.touched && meta.error ? (<span className="error-label">{meta.error}</span>) : null}
            </div>
            <select {...field} {...props} className={meta.touched && meta.error ? 'error' : ''} disabled={!enabled}>
                <option value={defaultValue}>{props.placeholder}</option>
                {values.map(element => {
                    return (<option key={element}>{element}</option>)
                })}
            </select>
            {meta.touched && meta.error ? (<WarningIcon value="orange" />) : icon}
        </div>
    );
}

export default FASelectField;
