import React from "react";
import './Confirm.scss';
import { t } from '../../languages/Translations'

const Confirm = (props) => {
    return (
        <div className="modal-confirm">
            <div className="modal-confirm-header">{props.title}</div>
            <div className={`modal-confirm-body ${props.cgu ? "cgu" : ""}`} dangerouslySetInnerHTML={{ __html: props.content }}></div>
            <div className="modal-confirm-answer">
                <button className="confirm" onClick={props.handleConfirm}>{props.cgu ? t('register_screen.cgu.accept') : t('common.confirm')}</button>
                <button className="cancel" onClick={props.handleCancel}>{props.cgu ? t('register_screen.cgu.decline') : t('common.cancel')}</button>
            </div>
        </div>
    );
}

export  {Confirm };
