import React from 'react';
import { useFormikContext } from 'formik';
import AttendeesList from "../attendees/AttendeesList";
import { MeetingInformation } from './MeetingDetails';
import { t } from '../../languages/Translations';

const MeetingStepValidation: React.FunctionComponent = () => {
    const formik: any = useFormikContext();

    return (
        <div className="meeting-content limited-width">
            <div className="meeting-container">
                <div className="meeting-info-container">
                    <MeetingInformation value={formik.values.reservation} />
                </div>
                <div className="attendees-container">
                    <div className="attendees-number">{formik.values.reservation.attendees.length > 1 ? formik.values.reservation.attendees.length + " " + t('common.attendees_number_label') : formik.values.reservation.attendees.length + " " + t('common.attendee_number_label')}</div>
                    <AttendeesList attendees={formik.values.reservation.attendees} />
                </div>
            </div>
        </div>
    );
}

export default MeetingStepValidation
