import {currentToken, convertStringDateToJsonDate, convertStringDateToJsonTime} from "../components/Utils";
import {ReservationFormData} from "../components/Types";

export async function searchRoom(data: ReservationFormData) {
    const options:RequestInit = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token: currentToken(),
        "day": convertStringDateToJsonDate(data.reservation.day),
        "fromTime": convertStringDateToJsonTime(data.reservation.fromTime),
        "toTime": convertStringDateToJsonTime(data.reservation.toTime),
        "capacity": data.info.capacity,
        "duration": data.info.duration
      })
    }

    return fetch(`${process.env.REACT_APP_PUBLIC_URL}/booking/102/room/search`, options).then(async (r) => {
        if (r.status != 200) throw "http:" + r.status;
        let json = await r.json();
        if (json.header.status === 0) throw json.header.error;
        return json.rooms;
    });
}
