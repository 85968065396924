import './Password.scss';
import React, {useEffect, useState} from "react";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FATextField } from "../common/form/FATextField";
import { BigButton } from "../common/FAButtons";
import { t } from '../../languages/Translations'
import {changePassword} from "../../api/user";
import {useHistory, useLocation} from "react-router-dom";
import Cookies from "universal-cookie";
import {userContext} from "../App";
import {ForwardArrowIcon} from "../common/Icons";
import { PasswordData} from "../Types";
import {currentToken} from "../Utils";

const initValues: PasswordData = { email: '', password:'', password2:'', key: null, captcha: null};

function Password({value:{ setLoading, displayToaster, setLoggedIn }}) {
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const history = useHistory();
  const query = useQuery();
  initValues.key = query.get("k") ? query.get("k") : "";
  initValues.email = query.get("e") ? query.get("e") : "";
  userContext.email = initValues.email

    useEffect(() => {
        if(!currentToken() && ! initValues.key) {
            window.location.href = "/"
        }
    });

  function useQuery() {
        return new URLSearchParams(useLocation().search);
  }

  function apply(data: PasswordData) {
      setIsError(false);
      setLoading(true);
      changePassword(data)
        .then(() => {
            setLoading(false);
            setLoggedIn(false);
            displayToaster({ message: t("password_screen.message_succeed"), error: false, timer:2000 })
            new Cookies().remove('token');
            window.location.href = "/"
        })
        .catch(e => {
            setLoading(false);
            setErrorMsg(t('password_screen.message_failed')+ " ("+e+")")
            setError()
          });
  }
  function setError() {
      setIsError(true);
      setTimeout(function () {
          setIsError(false);
      }, 5000)
  }

  return (
    <div className="content content-padding">
      <Formik
        initialValues={initValues}
        onSubmit={apply}
        validationSchema={Yup.object().shape({
            password: Yup.string().matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/, " ").required(" "),
            password2: Yup.string().oneOf([Yup.ref('password')], " ").required(" "),
        })}
      >
        {formik => (
          <form onSubmit={formik.handleSubmit}>
              <div className="title-container">
                  <h2>{t("password_screen.title")}</h2>
              </div>
              <p>&nbsp;</p>
              <p><span className="text-ellipsis">{t("password_screen.header")}</span></p>
              <p>&nbsp;</p>
              <div className="text-error">{isError && <span>{errorMsg}</span>}</div>
              <div>
                <FATextField
                  label={t('password_screen.password_new')}
                  name="password"
                  type="password"
                />
                <FATextField
                  label={t('password_screen.password_confirm')}
                  name="password2"
                  type="password"
                />
                <BigButton type="submit" label={t('password_screen.button')} color="orange" icon={<ForwardArrowIcon value="white" />} size="big" handleClick={() => { }} />
              </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export { Password };
