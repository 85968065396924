import React, { useState} from 'react';
import './NavBar.scss';

import {NavLink, useHistory, useLocation} from 'react-router-dom';
import { AgendaBgIcon, ContactsBgIcon, LogOutBgIcon, LogOutIcon, QrcodeBgIcon } from '../common/Icons';
import { t } from '../../languages/Translations';
import Cookies from 'universal-cookie';
import {Confirm } from "../common/Confirm";
import {Badge } from "../common/Badge";
import { logoutUser } from "../../api/user";
import {userContext} from "../App";

function NavBar({ value: { isLoggedIn, setLoading, displayBadge, setDisplayBadge } }) {
  const [displayConfirm, setDisplayConfirm] = useState(false);

  function logOut() {
    setDisplayConfirm(true);
  }

  function DisplayBadge() {
    setDisplayBadge(true)
    userContext.displaybadge = true;
  }

  function CloseBadge() {
    setDisplayBadge(false)
    userContext.displaybadge = false;
  }

  const history = useHistory();
  const location = useLocation();

  const routeChange = () =>{
    history.push("/");
  }

  function confirmLogout() {
    setDisplayConfirm(false);
    setLoading(true);
    logoutUser().then(() => {
      setLoading(false);
      new Cookies().remove('token');
      window.location.href = "/login"
    });
  }

  return (
    <nav>
      <div id="logo" onClick={routeChange}><span /></div>
      { isLoggedIn && location.pathname !== "/password" &&
        <ul className="nav-items">
          <li className="padding">
            <NavLink exact={true} to="/home" activeClassName="active">
              <span className="desktop-only">{t('common.nav_bar.meetings')}</span>
              <span className="mobile-only"><AgendaBgIcon value="white" /></span>
            </NavLink>
          </li>
          {/*<li className="padding">
            <NavLink to="/meeting" activeClassName="active">
              <span className="desktop-only">{t('common.nav_bar.new_meeting')}</span>
              <span className="mobile-only"><CalendarPlusBgIcon value="white" /></span>
            </NavLink>
          </li>*/}
          <li className="padding">
            <NavLink to="/contacts" activeClassName="active">
              <span className="desktop-only">{t('common.nav_bar.contacts')}</span>
              <span className="mobile-only"><ContactsBgIcon value="white" /></span>
            </NavLink>
          </li>
          { userContext && userContext.member.badge &&
            <li className="toppadding">
              <button onClick={DisplayBadge}>
                <span className="desktop-only">{t('common.nav_bar.badge')}</span>
              <span className="mobile-only"><QrcodeBgIcon value="white" /></span>
              </button>
            </li>
          }
          <li>
            <button onClick={logOut}>
              <span className="desktop-only"><LogOutIcon value="white" /></span>
              <span className="mobile-only"><LogOutBgIcon value="white" /></span>
            </button>
          </li>
        </ul>
      }
      {displayConfirm ? <Confirm title={t('common.confirmation')} content={t('home_screen.disconnect')} handleConfirm={() => confirmLogout()} handleCancel={() => setDisplayConfirm(false)} /> : null}
      { displayBadge ? <Badge data={userContext.member.badge} handleClose={() => CloseBadge()} /> : null}
    </nav>
  );
}


export { NavBar }
