import React from 'react'
import { useField } from 'formik';
import DatePicker, { registerLocale } from "react-datepicker";
import { WarningIcon } from '../Icons';
import './FADateField.scss';
import "react-datepicker/dist/react-datepicker.css";
import fr from 'date-fns/locale/fr';

interface DF {
    label: string,
    icon?: any,
    handleChange?: (number, any) => void,
    name: string,
    step?: string,
    className?: string,
    locale: string,
    showTimeSelect?: any,
    showTimeSelectOnly?: any,
    timeIntervals?: any,
    dateFormat: string,
    autoComplete: string,
    placeholderText: string,
    customInput?: any
    required?: boolean
}
const FADateField = ({ label, icon, handleChange, required, ...props }: DF) => {
    registerLocale('fr', fr);
    const [field, meta] = useField(props);

    function updateDate(date) {
        if (handleChange) {
            handleChange(Date.parse(date), date);
        }
    }

    return (
        <div className="fadatefield">
            <div className="flex">
                <label>{label}&nbsp;{required ? "*" : ""}</label>{meta.touched && meta.error ? (<span className="error-label">{meta.error}</span>) : null}
            </div>
            <DatePicker
                {...field}
                {...props}
                className={meta.touched && meta.error ? 'error' : ''}
                selected={(field.value && new Date(field.value)) || null}
                onChange={date => updateDate(date)}
                strictParsing
                onFocus={e => (e.target.readOnly = true)}
                customInput={<DateInput />}
            />
            <div className="icon-container">{meta.touched && meta.error ? (<WarningIcon value="orange" />) : icon}</div>
        </div>
    );
}
//Fix to disable keyboard on phone
class DateInput extends React.PureComponent {
    render() {
      return (
        <input
          {...this.props}
          onFocus={() => this.setState({readOnly: true})}
          onBlur={() => this.setState({readOnly: false})}
          readOnly={true}
        />
      );
    };
}

export default FADateField;
