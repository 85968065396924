import { ContactData } from "../Types";
import React from "react";
import './ContactCard.scss';
import {EditIcon, TrashIcon} from "../common/Icons";
import {SquareButton} from "../common/FAButtons";

/**
 *
 * @param props
 * @constructor
 */
function ContactCard(props: { contactData: ContactData, editButtons: boolean, horizontal?: boolean, handleChange, handleEdit, handleDelete}) {
  const { contactData, editButtons } = props;

  return (
    <div className={`card contact-item ${contactData.selected ? "selected ": ""}${props.horizontal ? "horizontal": ""}`} onClick={() => props.handleChange(contactData.code)}>
      <div className="contact-info">
      {displayInfo(contactData, props)}
      </div>
      <DisplayButtons editButtons={editButtons} handleEdit={props.handleEdit} handleDelete={props.handleDelete} />
    </div>
  )
}
/**
 *
 * @param props
 * @constructor
 */
function DisplayButtons (props) {
    if (props.editButtons) {
        return (
            <div className="button-container">
                <SquareButton
                    size='small'
                    color='light-grey'
                    className='left'
                    icon={<EditIcon color="default" size="small"/>}
                    handleClick={() => props.handleEdit()}
                />
                <SquareButton
                    size='small'
                    color='light-red'
                    className='left'
                    icon={<TrashIcon color="orange" size="small"/>}
                    handleClick={() => props.handleDelete()}
                />
            </div>
        )
    } else return null
}

/**
 *
 * @param contactData
 * @param props
 */
function displayInfo(contactData, props) {

        return (
            <>
            { (contactData.nameFirst || contactData.nameLast) && <span className="name">{contactData.nameLast} {contactData.nameFirst}</span> }
            { contactData.company && <span className="company">{contactData.company}</span>}
            <span className="address">{contactData.email}</span>
            </>
        );
}

export default ContactCard;
