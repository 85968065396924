import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form, FormikValues } from 'formik';
import * as Yup from 'yup';
import MeetingStepDate from './MeetingStepDate';
import MeetingStepRoom from './MeetingStepRoom';
import MeetingStepAttendees from './MeetingStepAttendees';
import MeetingStepValidation from './MeetingStepValidation';
import { STEP_TITLES, STEP_BUTTONS } from '../Constant'
import { ForwardArrowIcon } from '../common/Icons';
import { BigButton } from '../common/FAButtons';
import './MeetingForm.scss';
import { ReservationFormData} from '../Types';
import {createMeeting} from '../../api/meeting';
import { t } from '../../languages/Translations';
import {userContext} from "../App";

// -----TYPES------
type MeetingFormType = {
  initialValues: ReservationFormData,
  onSubmit: (val: ReservationFormData) => void,
  children: FormikValues
}

// -----BOOKING CONTAINER ----
function Meeting({value:{ setLoading, displayToaster }}) {
  let history = useHistory();
  return (
    <MeetingForm
      initialValues={{
        info: {
          duration: undefined,
          capacity: 1,
          room: '',
          floor: '',
          location: '',
          step: 0
        },
        reservation: {
          code: '',
          name: '',
          day: '',
          fromTime: '',
          toTime: '',
          room: {
            code: '',
            name: '',
            label: '',
            location: {
              name: '',
              code: '',
              label: '',
              category: 0,
              addresses: []
            },
            floor: undefined,
            maxCapacity: 0,
            maxDuration: 0,
            size: undefined,
            address: undefined,
            equipments: [],
            thumbnailUrl: undefined,
            selected: false
          },
          badge: {
            service: 100,
            tokenBinary: '',
            tokenSerial: '',
            tokenData: '',
          },
          service: 100,
          attendees: []
        }
      }}
      onSubmit={(val) => {
          setLoading(true);
          createMeeting({...val})
              .then((res) => {
                val.reservation.code = res.reservation.code;
                val.reservation.badge = res.reservation.badge;
                val.reservation.attendees = res.reservation.attendees;
                userContext.contacts.map(contact => {
                  contact.selected = false;
                })
                setLoading(false)
                displayToaster({ message: t("new_meeting_form.create_succeed"), error: false })
                history.push("/home")
              }).catch(e => {
                setLoading(false)
                displayToaster({ message: t("new_meeting_form.create_error") + " (" + e + ")", error: true })
          });
      }}
    >
      <MeetingStepDate
        validationSchema={Yup.object().shape({
          reservation: Yup.object().shape({
            name: Yup.string().matches(/^[a-zA-Z0-9\-.%$\/\\_àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ@'_ ]+$/, " ").required(" "),
            day: Yup.date().nullable().default(null).required(t('new_meeting_form.errors.mandatory_field')).min(new Date(new Date().setHours(0, 0, 0, 0))).required(" "),
            fromTime: Yup.date().nullable().default(null).required(t('new_meeting_form.errors.mandatory_field'))
                .when("date",  {
                  is: (date => { return date < new Date()}),
                  then: Yup.date().min(new Date(), t('new_meeting_form.errors.invalid_time'))
                }),
            toTime: Yup.date().nullable().default(null).min(Yup.ref('fromTime'), t('new_meeting_form.errors.invalid_time')).required(" ")
          }),
        })}
      />
      <MeetingStepRoom value={{ setLoading, displayToaster }}/>
      <MeetingStepAttendees value={{ setLoading, displayToaster }} />
      <MeetingStepValidation />
    </MeetingForm>
  )
}

// -----BOOKING FORM ----
// It uses the stepper component and all the child step component

const MeetingForm: React.FunctionComponent<MeetingFormType> = (form) => {

  const [stepNumber, setStepNumber] = useState(0);
  const steps = React.Children.toArray(form.children);

  const [snaphsot, setSnapshot] = useState(form.initialValues);

  const step = steps[stepNumber] as FormikValues;
  const totalSteps = steps.length;
  const isLastStep = stepNumber === totalSteps - 1;

  const previous = (values: ReservationFormData) => {
    setSnapshot(values);
    setStepNumber(Math.max(stepNumber - 1, 0));
  }

  const next = (values: ReservationFormData) => {
    setSnapshot(values);
    setStepNumber(Math.min(stepNumber + 1, totalSteps - 1));
  }

  const handleSubmit = async (values: ReservationFormData) => {
    if (isLastStep) {
      return form.onSubmit(values);
    } else {
      next(values);
    }
  }

  ///Start handling resizing
  const { height, width } = useWindowDimensions();

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
  }
  ///End handling resizing

  function RenderButton(formik) {
    if (stepNumber === 0) {
      const enable = formik.values.reservation.name && formik.values.reservation.day && formik.values.reservation.fromTime && formik.values.reservation.toTime
      return <BigButton width={width} height={height} label={STEP_BUTTONS[stepNumber]} disabled={!enable} handleClick={() => { }} icon={<ForwardArrowIcon value={enable ? "white" : "orange"} />} />
    }
    else if (stepNumber === 1) {
      const enable = formik.values.reservation.room && formik.values.reservation.room.code && formik.values.reservation.room.code !== '';
      return <BigButton width={width} height={height} label={STEP_BUTTONS[stepNumber]} disabled={!enable} handleClick={() => { }} icon={<ForwardArrowIcon value={enable ? "white" : "orange"} />} />
    }
    else if (stepNumber === 2) {
      const enable = formik.values.reservation.attendees && (formik.values.reservation.room.maxCapacity === 0 || (formik.values.reservation.attendees.length +1) <= formik.values.reservation.room.maxCapacity)
      return <BigButton width={width} height={height} label={STEP_BUTTONS[stepNumber]} disabled={!enable} handleClick={() => { }} icon={<ForwardArrowIcon value={enable ? "white" : "orange"} />} />
    }
    else
      return <BigButton width={width} height={height} label={STEP_BUTTONS[stepNumber]} type="submit" disabled={formik.isSubmitting} handleClick={() => {}} icon={<ForwardArrowIcon value="white" />} />;
  }

  return (
    <Formik
      initialValues={snaphsot}
      onSubmit={handleSubmit}
      validationSchema={step.props.validationSchema}
    >
      {formik => (
        <Form>
          <div className={stepNumber === 3 ? 'header-img' : ''}></div>
          <div className={stepNumber === 3 ? 'modal form-content' : 'form-content'}>
            <Stepper
              step={stepNumber}
              title={formik.values.reservation.name}
              handleBack={() => previous(formik.values)}
            />
            {step}
            <div className="next right">
              {RenderButton(formik)}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

// -----STEPPER ----
type StepperType = {
  step: number,
  title: string,
  handleBack: () => void
}
const Stepper: React.FunctionComponent<StepperType> = (stepper) => {

  function getStepTitle(step: number) {
    if (step === 3) {
      return stepper.title;
    }
    return STEP_TITLES[step];
  }

  function renderArrow(step: number) {
    if (step > 0) {
      return <button type="button" onClick={stepper.handleBack}><img src="/icons/backArrow.svg" alt="" /></button>;
    }
  }

  return (
    <div className="pagination">
      <div className="pagination-bullets">
        <span className={`pagination-bullet ${stepper.step === 0 ? 'pagination-bullet-active' : ''}`}></span>
        <span className={`pagination-bullet ${stepper.step === 1 ? 'pagination-bullet-active' : ''}`}></span>
        <span className={`pagination-bullet ${stepper.step === 2 ? 'pagination-bullet-active' : ''}`}></span>
        <span className={`pagination-bullet ${stepper.step === 3 ? 'pagination-bullet-active' : ''}`}></span>
      </div>
      <div className="title-container">{renderArrow(stepper.step)} <h2>{getStepTitle(stepper.step)}</h2></div>
    </div>
  );
}

export default Meeting;
