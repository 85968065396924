import React, {  useEffect } from 'react';
import { AgendaIcon } from '../common/Icons';
import { FormikValues, useFormikContext } from 'formik';
import { FATextField } from '../common/form/FATextField';
import FADateField from '../common/form/FADateField';
import FANumberField from '../common/form/FANumberField';
import { t } from '../../languages/Translations';

const MeetingStepDate: React.FunctionComponent<FormikValues> = () => {
    const formik: any = useFormikContext();

    //Update 
    useEffect(() => {
        //Calculate an endTime based on a start date and a duration
        function calculateEndTime(startDate: number, duration: number): Date {
            return new Date(startDate + (duration * 60000));
        }
        //Set endTime only if start and duration are valid
        if (formik.values.reservation.fromTime && formik.values.info.duration > 0) {
            const endTime = calculateEndTime(Date.parse(formik.values.reservation.fromTime), formik.values.info.duration);
            formik.setFieldValue('searchRoomForm.toTime', endTime);
            formik.values.reservation.toTime = endTime
        }
        // eslint-disable-next-line
    }, [formik.values.info.duration]);

    function updateDate(timestamp: number, date: Date) {
        formik.setFieldValue('reservation.day', date);
        formik.values.reservation.day = date
    }

    function updateFromTime(timestamp: number, date: any) {
        formik.setFieldValue('reservation.fromTime', date);
        formik.values.reservation.fromTime = date
        if (formik.values.reservation.toTime) updateDuration();
        if(formik.values.info.duration && !formik.values.reservation.toTime)
            calculateEndTime();
    }

    function updateToTime(timestamp: number, date: any) {
        formik.setFieldValue('reservation.toTime', date);
        formik.values.reservation.toTime = date
        if (formik.values.reservation.fromTime) updateDuration();
        if(formik.values.info.duration && !formik.values.reservation.fromTime)
            calculateFromTime();
    }

    function updateDuration() {
        const fromTime = Date.parse(formik.values.reservation.fromTime);
        const toTime = Date.parse(formik.values.reservation.toTime);
        formik.setFieldValue('info.duration', calculateDuration(fromTime, toTime))
    }

    function calculateEndTime(){
        const fromTime = Date.parse(formik.values.reservation.fromTime);
        const duration = formik.values.info.duration;
        formik.setFieldValue('reservation.toTime', new Date(fromTime + (duration * 60000)));
    }

    function calculateFromTime(){
        const toTime = Date.parse(formik.values.reservation.toTime);
        const duration = formik.values.info.duration;
        formik.setFieldValue('reservation.toTime', new Date(toTime - (duration * 60000)));
    }

    function calculateDuration(startDate: number, endDate: number): number {
        if (startDate <= endDate) {
            return Math.round((endDate - startDate) / 60000);
        }
        return 0;
    }

    function convertNumberToDate(num: number) {
        if (!num) {
            return '0h00';
        }
        const hours = Math.floor(num / 60);
        const minutes = (num % 60);
        let minText = minutes.toString();
        if (minutes === 0) {
            minText = minText + '0';
        }
        return `${hours}h${minText}`;
    }

    return (
        <div className="meeting-content limited-width overflow ">
            <FATextField
                label={t('new_meeting_form.labels.name')}
                name="reservation.name"
                type="text"
                required={true}
            />
            <FADateField
                label={t('new_meeting_form.labels.date')}
                name="reservation.day"
                className="center"
                locale="fr"
                dateFormat="d MMMM yyyy"
                autoComplete="off"
                placeholderText=""
                icon={<AgendaIcon value="orange" />}
                required={true}
                handleChange={updateDate}
            />
            <div className="meeting-flex">
                <div className="hour-picker left">
                    <FADateField
                        label={t('new_meeting_form.labels.hour_start')}
                        name="reservation.fromTime"
                        step="1800"
                        className="center"
                        locale="fr"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        dateFormat="p"
                        autoComplete="off"
                        placeholderText=""
                        handleChange={updateFromTime}
                        required={true}
                    />
                </div>
                <div className="hour-picker center">
                    <div className="separator" />
                </div>
                <div className="hour-picker right">
                    <FADateField
                        label={t('new_meeting_form.labels.hour_end')}
                        name="reservation.toTime"
                        step="1800"
                        className="center"
                        locale="fr"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        dateFormat="p"
                        autoComplete="off"
                        placeholderText=""
                        handleChange={updateToTime}
                        required={true}
                    />
                </div>
            </div>
            <FANumberField
                label={t('new_meeting_form.labels.duration')}
                name="info.duration"
                type="hidden"
                placeholder=""
                className="center"
                backgroundColor="grey"
                value={30}
                displayHandler={convertNumberToDate}
                min={30}
                max={240}
                step={30}
            />
            <FANumberField
                label={t('new_meeting_form.attendees_number_label')}
                name="info.capacity"
                placeholder="0"
                className="center"
                min={1}
                max={20}
                step={1}
                initial={1}
                backgroundColor="grey"
            />
            <div style={{height:100}}/>
        </div>

    );
}

export default MeetingStepDate;
