import {Link} from 'react-router-dom';
import './HomePage.scss';
import React, {useState, useEffect} from 'react';
import { meetingList, deleteMeeting } from '../../api/meeting';
import { MeetingData } from '../../components/Types'
import { BigButton, SquareButton } from '../common/FAButtons';
import { PlusIcon, TrashIcon } from '../common/Icons';
import { Confirm } from '../common/Confirm';
import { t } from '../../languages/Translations';
import {convertDateFromString, convertToDateFromDateStringAndTimeString, currentToken} from '../../components/Utils'
import { userContext} from "../App";
import {fetchContacts} from "../../api/contact";

/**
 *
 * @param meetingNumber
 * @constructor
 */
function Header(props: { value: number, loading: boolean }) {
  return (
    <div className="home-header">
      <h2 className="desktop-only">{t('common.my_meetings')}</h2>
      { !props.loading && <span className="meeting-number">{props.value} {t('home_screen.future_meetings_number_label')}</span>}
    </div>
  );
}

/**
 *
 * @param props
 * @constructor
 */
function Card(props: { value: MeetingData }) {
  const meeting = props.value;
  const attendees = meeting.attendees.length > 1 ? meeting.attendees.length + " " + t('common.attendees_number_label') : meeting.attendees.length + " " + t('common.attendee_number_label');
  
  return (
    <Link to={{
      pathname: "/meeting/details",
      state: meeting
    }}>
      <div className="card-header">
        <div className="title text-ellipsis">{meeting.name}</div>
      </div>
      <div className="card-content">
        <div className="card-row">
          <div className="text-ellipsis"><img src="/icons/agenda.svg" alt="" /><span>{convertDateFromString(meeting.day)}</span></div>
          <div className="text-ellipsis"><img src="/icons/compass.svg" alt="" /><span>{meeting.room.location?.label}</span></div>
        </div>
        <div className="card-row">
          <div className="text-ellipsis"><img src="/icons/clock.svg" alt="" /><span>{meeting.fromTime.substring(0,5)} - {meeting.toTime.substring(0,5)}</span></div>
          <div className="text-ellipsis"><img src="/icons/user.svg" alt="" /><span>{attendees}</span></div>
        </div>
      </div>
    </Link>
  )
}

/**
 *
 * @param setLoading
 * @param setToast
 * @constructor
 */
function Home({value:{ setLoading, loading, displayToaster }}) {
  const [meetings, setMeetings] = useState(Array<MeetingData>());
  const [meetingId, setMeetingId] = useState("");
  const [displayConfirm, setDisplayConfirm] = useState(false);

  useEffect(() => {
    // load meeting list
    if(userContext.pwdchange) return;
    setLoading(true);
    meetingList().then((res) => {
       setMeetings(orderMeeting(res.reservations));
       setLoading(false);
    }).catch(e => {
      setLoading(false)
      displayToaster({message:t('meeting_detail.meeting_fetch_error'), error: true })
    });
    // load contacts if necessary
    if(userContext.contacts == null || userContext.contacts.length == 0) {
      setLoading(true);
      fetchContacts().then((res) => {
        userContext.contacts = res.contacts;
        setLoading(false);
      }).catch(e => {
        setLoading(false)
        displayToaster({message: t('contacts_screen.fetch_failed'), error: true })
      });
    }
  }, []);

  function orderMeeting(meetings: Array<MeetingData>) {
    return meetings.sort(function (a, b) {
      const aDate = convertToDateFromDateStringAndTimeString(a.day, a.fromTime);
      const bDate = convertToDateFromDateStringAndTimeString(b.day, b.toTime);
      if (aDate.getTime() > bDate.getTime()) return 1;
      if (aDate.getTime() < bDate.getTime()) return -1;
      return 0;
    });
  }
  function confirmDeleteMeeting(meetingId: string) {
    cancelConfirm();
    // check meeting is not started
    const current = meetings.find(i => i.code === meetingId);

    // delete using API
    setLoading(true);
    deleteMeeting(meetingId).then((res) => {
      setLoading(false);
      const others = meetings.filter(i => i.code !== meetingId);
      setMeetings(others);
      displayToaster({ message:t("new_meeting_form.delete_succeed"), error: false })
    }).catch(e=> {
      setLoading(false);
      const code = "codes.err"+e;
      let msg = t(code);
      if(msg == null || msg.length == 0) msg = t("new_meeting_form.delete_error")+" ("+e+")"
      displayToaster({ message: msg, error: true  })
    });
  }

  function confirmDelete(id) {
    setMeetingId(id);
    setDisplayConfirm(true);
  }

  function cancelConfirm() {
    setMeetingId("");
    setDisplayConfirm(false);
  }

  return (
    <div className="content">
      <div className="content-padding">
        < Header value={meetings.length} loading={loading} />
        <div className="fixed-button">
          <Link to="/meeting"><BigButton label={t('home_screen.new_meeting_button')} handleClick={() => { }} icon={<PlusIcon size="big" color="white" />} /></Link>
        </div>
        <div className="meeting-list">
          {meetings.map(meeting =>
            <div className="card meeting-item" key={meeting.code}>
              <SquareButton
                size='small'
                color='light-red'
                className='trash-icon-button'
                icon={<TrashIcon color="orange" size="small" />}
                handleClick={() => { confirmDelete(meeting.code) }}
              />
              <Card value={meeting} />
            </div>
          )}
        </div>
        {displayConfirm ? <Confirm title={t('common.confirmation')} content={t('home_screen.cancel_meeting')} handleConfirm={() => confirmDeleteMeeting(meetingId)} handleCancel={() => cancelConfirm()} /> : null}
      </div>
    </div>
  );
}

export { Home };
