import React from "react";
import {FormikValues} from 'formik';
import './Register.scss';
import FACheckbox from "../common/form/FACheckbox";

const RegisterStepCgu: React.FunctionComponent<FormikValues> = (props) => {

    return (
        <div className="meeting-content limited-width overflow ">
            <div className="cgu">
                <p>1. Objets des conditions générales
                    <br/>Les présentes Conditions Générales d’Utilisation, aussi appelées « CGU », ont pour objet de définir les modalités de mise à disposition de l’Application, accessible à l’adresse : https://app.flexaccess.fr, permettant aux clients de Nexity de réserver des espaces de travail dont l’ouverture est contrôlée par un lecteur FlexAccess.
                    <br/>Les Conditions Générales d'Utilisation s’appliquent à tout accès, consultation et utilisation de l‘Application par le Client.
                    <br/>Elles en constituent les conditions essentielles et déterminantes et prévalent sur toutes autre conditions générales et/ou tous autres documents émanant du Client, quels qu’en soient les termes. Aussi, toute demande de souscription à une offre de Services proposée par la Société et/ou l’utilisation de ces Services implique l’acceptation par le Client sans réserve des présentes conditions générales.
                    <br/>Le Client est informé que les présentes conditions générales peuvent être modifiées à tout moment par la Société. Dans cette hypothèse, les conditions générales modifiées seront applicables à toute relation contractuelle en cours et à venir.
                    <br/>Les termes précédés d’une majuscule sont définis à l’article « Définitions ».
                </p>
                <p>2. Définitions
                    <br/>« Application » : désigne l’application FlexAccess accessible à l’adresse https://app.flexaccess.fr permettant aux clients d’accéder aux Services.
                    <br/>« Client » : désigne toute personne physique ou morale souscrivant à des fins professionnelles, et selon les modalités et conditions prévues aux Contrat, au Service proposé par la Société.
                    <br/>« Conditions Générales d’Utilisation » : désignent les présentes conditions générales d’utilisation, qui s’appliquent aux Services tels que définis ci-après.
                    <br/>« Proposition commerciale » : désigne l’offre commerciale faite au Client par la Société, laquelle contient l’ensemble des conditions particulières tel que le prix, la durée et la description spécifique des Services.
                    <br/>« Service » : désigne le service « Mes réservations » accessibles via le Site. Ces Services sont mis à disposition des Utilisateurs, au sein de leur compte Utilisateur. Ces Services peuvent évoluer dans le temps.
                    <br/>« Site ou Site Internet » : désigne le service électronique interactif édité et exploité par la Société, accessible notamment à l'adresse https://app.flexaccess.fr, qui permet l'accès aux Services.
                    <br/>« Société » : désigne la société Nexity Solutions Digitales, éditeur et propriétaire de l’Application.
                    <br/>« Utilisateur » : désigne toute personne physique à qui la Société donne un accès exclusivement personnel à l’Application, par l’utilisation d’un identifiant et d’un mot de passe, soumis aux Conditions Générales d’Utilisation de l’Application.
                </p>
                <p>3. Acceptation des Conditions - modification des Conditions
                    <br/>L'Utilisateur reconnaît avoir pris connaissance au moment de la consultation et de l'utilisation de l'Application, des présentes Conditions Générales sur cet écran et déclare expressément les accepter sans réserve.
                    <br/>Les présentes Conditions Générales pouvant faire l'objet de modifications, les conditions applicables sont celles en vigueur et accessibles sur l'Application de la Société à la date d'accès à l'Application par l'Utilisateur.
                    <br/>L'Utilisateur reconnaît disposer de la compétence et des moyens nécessaires pour accéder et utiliser l'Application, avoir vérifié que la configuration informatique utilisée par lui ne contient aucun virus et qu'elle est en bon état de fonctionnement.
                    <br/>Les validations sont considérées par l'Utilisateur comme ayant la valeur d'une acceptation des pages parcourues, et ainsi, l'Utilisateur reconnaît avoir été complètement informé du contenu des pages concernées lorsqu'il procède à la validation.
                </p>
                <p>4. Accès à l’Application
                    <br/>4.1. Accès aux Services
                    <br/>Le Service est accessible en ligne sur l'Application de la Société.
                    <br/>Le Client devra s’assurer avoir la dernière version du navigateur web qu’il utilise pour utiliser l’Application. Les navigateurs Chrome, Edge, Firefox, Safari, Brave, Opera sont notamment compatibles avec l’Application. Le navigateur Microsoft Internet Explorer n’est pas compatible avec l’Application.
                    <br/>La Société met à la disposition du Client l’Application, pour la durée prévue aux conditions particulières de la Proposition commerciale qu’il a souscrite.
                    <br/>L’accès à l’Application et à ses Services est strictement réservé au Client qui bénéficie d’un droit d’usage privé personnel, non transmissible et non exclusif. En fonction de la formule souscrite, le Client peut être autorisé à créer plusieurs comptes utilisateurs, chacun correspond à un Utilisateur déterminé. Le Client garantit que l’ensemble des informations communiquées lors de la création de chaque compte utilisateur est exact et complet. Le Client est seul responsable de la gestion, du maintien et de la sécurité des accès à l’Application par ses Utilisateurs. Il lui appartient en conséquence de prendre toutes les dispositions nécessaires pour maintenir et sécuriser leur accès.
                    <br/>Le Client désignera les Utilisateurs auxquels il donne accès à l’Application. Il devra s’assurer que ces derniers respectent ses Conditions Générales d’Utilisation.
                    <br/>4.2. Confidentialité des Identifiants
                    <br/>Pour accéder à l’Application, chaque Utilisateur dûment autorisé doit activer son propre compte grâce au lien/code d’activation et l’identifiant transmis par la Société. L’Utilisateur devra choisir un mot de passe strictement personnel et confidentiel.
                    <br/>Le Client et ses Utilisateurs s’engagent à garder secret l’ensemble des identifiants et mots de passe et à ne pas les divulguer sous quelque forme que ce soit et à qui que ce soit.
                    <br/>L’Utilisateur s’engage à informer immédiatement la Société en cas de perte ou de divulgation de ses identifiants. L’Utilisateur sera tenu responsable de tout dommage résultant de la perte ou de la divulgation de ses identifiants.
                    <br/>4.3. Disponibilité
                    <br/>La Société fait ses meilleurs efforts afin de rendre son Service disponible 24 heures sur 24 et 7 jours sur 7, indépendamment des opérations de maintenance dudit Service. A ce titre, la Société est tenue d'une obligation de moyens.
                    <br/>La Société se réserve la possibilité de modifier, interrompre, à tout moment, temporairement ou de manière permanente tout ou partie du Service et ou de l'Application sans information préalable des Utilisateurs et sans droit à indemnités, la Société ne pouvant être tenue responsable des conséquences résultant de telles interruptions ou modifications.
                    <br/>Plus généralement, la Société se réserve le droit d'apporter toutes modifications de quelque nature qu'elles soient au contenu du Site, les informations communiquées n'étant garanties qu'à la date de mise à jour de l'Application mentionnée sur la page d’accueil, l’Utilisateur au-delà de cette date devant s’assurer du maintien des conditions proposées.
                </p>
                <p>5. Description des Services
                    <br/>L’Application permet la réservation d’espaces de travail, tels que des salles de réunion et bureaux, dont l’ouverture est contrôlée par un contrôleur d’accès FlexAccess, et la génération de droits d’accès temporaires, interprétables par les contrôleurs d’accès FlexAccess, pour accéder à ces espaces le temps de la réservation.
                    <br/>5.	Description des Services
                    <br/>5.1. Consultation des salles disponibles
                    <br/>L’Utilisateur peut consulter en temps réel pour chaque bâtiment sélectionné, le nombre de salles disponibles au sein de ce bâtiment.
                    <br/>5.2. Réservation des salles
                        <br/>L’Utilisateur peut sélectionner une salle disponible et la réserver pour un créneau donné.
                        <br/>L’accès à la salle se fait soit par présentation d’un code QR, ou un code pin, généré et consultable dans l’Application et/ou envoyé par email; soit directement avec le badge de l’Utilisateur (badge RFID) qui est reconnu par le contrôleur d’accès FlexAccess de la salle réservée, et ce pour le temps de la réservation.
                        <br/>L’Utilisateur a la possibilité d’inviter des contacts à accéder à la salle réservée, selon les mêmes modalités d’accès décrites ci-avant.
                    <br/>5.3. Contacts
                    <br/>L’Utilisateur a la possibilité de sauvegarder dans son compte sa liste de contacts personnelle.
                </p>
                <p>6. Souscription aux Services
                    <br/>La souscription aux Services visés à l’article 5 est formalisée par l’acceptation par le Client de la Proposition commerciale faite par la Société.
                    <br/>La Proposition commerciale comprend la durée de souscription aux Services, sa date de prise d’effet, et le prix auquel le Client accède aux Services.
                    <br/>En toute hypothèse, le bénéfice d’une souscription aux Services est personnel au Client bénéficiaire de la Proposition commerciale et ne peut être cédé à un tiers.
                    <br/>La date de fin de souscription aux Services entraîne l’impossibilité pour le Client et ses Utilisateurs d’accéder à l’Application et aux Services.
                </p>
                <p>7. Garantie de l'Utilisateur
                    <br/>En accédant à l'Application de la Société, l'Utilisateur déclare, garantit et s'engage à :
                    <br/>- accéder et utiliser l'Application et le Service en toute bonne foi, conformément aux termes des présentes Conditions générales et pour un usage personnel ;
                    <br/>- n’utiliser aucun dispositif ayant pour objet et/ou pour effet d'affecter le bon fonctionnement de l'Application et/ou du Service qu'il contient ;
                    <br/>- ne pas accéder et/ou utiliser l'Application et/ou le Service fourni par la Société sur ledit Application à des fins illicites et/ou dans le but de causer un préjudice à la réputation et l'image de la Société ou plus généralement à porter atteinte aux droits, notamment de propriété intellectuelle, de la Société et/ou de tiers ;
                    <br/>- ne pas reproduire, représenter tout ou partie de l'Application à des fins privées au-delà des exceptions légales prévues, en particulier par le Code de la propriété intellectuelle, ou en vue d'une commercialisation directe ou indirecte notamment auprès de tiers ;
                    <br/>- ne pas procéder à du rétro-engineering de quelque façon sur toute ou partie de l’Application, ne pas décompiler le code de l’Application ; ne pas nuire de quelque façon que ce soit à l’Application, que ce soit par l’installation de virus ou autre ;
                    <br/>- ne pas contrevenir aux dispositions des articles 323-1 à 323-7 du Code Pénal réprimant les pratiques dites de " hacking " ;
                    <br/>- ne pas tenter d’accéder aux bases de données de l'Application aux fins notamment d’utiliser et/ou exploiter les coordonnées électroniques et/ou postales des autres Utilisateurs de l'Application.
                    <br/>En cas de manquement à l'une ou l'autre de ces obligations et, sans que cette liste ne soit limitative, l'Utilisateur reconnaît et accepte que la Société aura la faculté de lui refuser, unilatéralement et sans notification préalable, l'accès à tout ou partie de l'Application, sans préjudice de toute action, réclamation, indemnisation que la Société pourrait réclamer à l’Utilisateur du fait de ces manquements.
                </p>
                <p>8. Garantie de la Société
                    <br/>La Société garantit qu’elle détient tous les droits nécessaires pour concéder au Client le droit d’utiliser l’Application tel que stipulé dans le contrat.
                    <br/>La Société ne garantit pas que les fonctionnalités de l’Application et de ses Services satisfassent les exigences et besoins spécifiques du Client. Il appartient au Client de vérifier l’adéquation de l’Application et de ses Services avec ses besoins et de prendre toutes les précautions nécessaires. La Société ne garantit pas les performances et résultats, notamment techniques, économiques et/ou commerciaux, de la Plateforme et de ses Services.
                    <br/>La Société ne garantit pas que l’Application soit exempte d’erreurs, de défauts, de logiciels malveillants ou de virus.
                    <br/>Le Client reconnaît que la Société n'a aucun contrôle sur le transfert de contenus via les réseaux de télécommunication publics utilisés pour accéder à l’Application et notamment le réseau Internet. Ainsi, la Société ne garantit pas la confidentialité des contenus lors du transfert de ceux-ci sur lesdits réseaux publics. En conséquence, il ne pourra en aucun cas voir sa responsabilité engagée en cas, notamment, de détournement, de captation, de corruption des contenus, ou de tout autre évènement susceptible d'affecter ceux- ci, survenant à l'occasion de leur transfert sur les réseaux de télécommunication publics.
                </p>
                <p>9. Limitation de responsabilité
                    <br/>L'Utilisateur est seul responsable de l'utilisation qu'il fait de l'Application et du Service auquel il accède depuis l'Application.
                    <br/>La Société ne pourra en aucun cas être tenue responsable dans le cadre d'une procédure introduite à l'encontre de l'Utilisateur qui se serait rendu coupable d'une utilisation non-conforme de l'Application et/ou du Service qu'il procure.
                    <br/>L'Utilisateur reconnaît et accepte à cet égard qu'il fera son affaire personnelle de toute réclamation ou procédure formée contre la Société, du fait de l'utilisation non-conforme par lui du Service et/ou de l'Application.
                    <br/>En toute hypothèse, la Société ne saurait être responsable :
                        <br/>- en cas d'indisponibilité du Service pour des raisons telles que la défaillance du réseau public d'électricité, la défaillance des réseaux câblés de télécommunications, la perte de connectivité au réseau Internet due aux opérateurs publics ou privés, notamment de l'Utilisateur, dont les causes proviennent notamment de grèves, de tempêtes, de tremblements de terre ou de toute autre cause ayant les caractéristiques de la force majeure ;
                        <br/>- dans les limites de la législation en vigueur, pour tout dommage indirect et ce y compris notamment les pertes de profit, de données ou tout autre perte de biens incorporels, et ce même si la Société a été informé de la potentialité de tels dommages, pouvant survenir (i) de l'utilisation ou de l'impossibilité d'utiliser le Service (ii) suite à l'accès du dit Service par un Utilisateur non autorisé.
                    <br/>La Société ne saurait être tenue responsable de tout dysfonctionnement de quelque nature qu'il soit relatif au matériel informatique de l'Utilisateur ainsi qu'à sa connexion d'accès à Internet, lors de l'accès à l'Application et plus généralement au Service.
                    <br/>Plus particulièrement, la Société ne saurait garantir l'Utilisateur du Service sur :
                        <br/>- les problèmes de vitesses d'accès à l'Application et/ou de vitesse de mise en mémoire tampon de l'ordinateur de l'Utilisateur des vidéos accessibles via le Service que l'Utilisateur serait susceptible de rencontrer ;
                </p>
                <p>10. Assistance technique et mises à jour
                    <br/>Un support technique permettant au Client de contacter la Société afin notamment de signaler les anomalies affectant l’Application ou pour toute question relative à ses Services est disponible en envoyant un courriel à l’adresse suivante : flexaccess@nexity.fr.
                    <br/>La Société se réserve le droit de faire évoluer l’Application.
                    <br/>En cas de modification substantielle des fonctionnalités de l’Application, la Société en informera le Client au minimum quinze (15) jours avant la mise à jour.
                </p>
                <p>11. Force majeure
                    <br/>La responsabilité de la Société ne pourra pas être recherchée si l'exécution de l'une de ses obligations est empêchée ou retardée en raison d'un cas de force majeure tel que définie par l’article 1218 du code civil, et notamment les pandémies, catastrophes naturelles, incendies, dysfonctionnement ou interruption du réseau de télécommunications ou du réseau électrique.
                </p>

                <p>12.	 Propriété intellectuelle
                    <br/>La Société est le titulaire ou le concessionnaire des droits de propriété intellectuelle tant de la structure générale de l'Application que de son contenu (textes, slogans, graphiques, images, vidéos, photos et autres contenus).
                    <br/>Toute représentation, reproduction, modification, dénaturation et/ou exploitation totale ou partielle de l'Application et/ou de son contenu et/ou du Service, par quelque procédé que ce soit et sur quelque support que ce soit, sans l'autorisation expresse et préalable de la Société, est prohibée et constitue notamment une contrefaçon sanctionnée par les articles L.335-2 et suivants du Code de la propriété intellectuelle, passible de poursuite judiciaires.
                    <br/>La mise à disposition des Services de l’Application par la Société n’implique aucun transfert de propriété quel qu’il soit au bénéfice du Client.
                    <br/>
                    <br/>En contrepartie du respect de l’ensemble de ses obligations par le Client, la Société lui concède un droit limité, révocable, personnel, non cessible, non transférable et non exclusif d’utilisation des Services de l’Application, pour la durée de la souscription telle que prévue dans la Proposition commerciale faite au Client.
                    <br/>Les marques, logos, dénominations sociales, sigles, noms commerciaux, enseignes et/ou nom de domaine de la Société, du groupe Nexity et/ou de ses partenaires commerciaux mentionnés sur l'Application, permettant l'accès au Service mis à disposition par la Société, constituent des signes distinctifs insusceptibles d'utilisation sans l'autorisation expresse et préalable de leur titulaire.
                    <br/>oute représentation et/ou reproduction et/ou exploitation partielle ou totale de ces signes distinctifs est donc prohibée et constitutif de contrefaçon de marque.
                    <br/>Les logiciels, bases de données, textes, images animées ou fixes, savoir-faire, dessins, illustrations et tout autre élément composant l'Application sont la propriété de la Société.
                    <br/>Les textes, images, logos, photographies, dessins, illustrations, reproduits sur l'Application, ne peuvent être totalement ou partiellement copiés, reproduits ou cédés.
                </p>
                <p>13.	Données personnelles
                    <br/>Nexity Solutions Digitales, en sa qualité de responsable de traitement, réalise des traitements de données à caractère personnel pour la gestion de ses relations avec ses clients.
                    <br/>Ce traitement est fondé sur la base de votre consentement préalable.
                    <br/>Les données collectées sont destinées aux services concernés du groupe Nexity, et le cas échéant à ses sous-traitants et prestataires. Les sous-traitants et prestataires en question sont soumis à une obligation de confidentialité et ne peuvent utiliser vos données qu'en conformité avec nos dispositions contractuelles et la législation applicable.
                    <br/>Elles sont conservées pendant la durée nécessaire à la réalisation de la finalité.
                    <br/>Conformément à la législation en vigueur, vous avez la possibilité d'exercer vos droits pour accéder, rectifier, effacer les données, limiter leurs traitements, vous y opposer et demander la portabilité de celles-ci. Vous pouvez également définir des directives relatives à la conservation, à l'effacement et à la communication de ses données à caractère personnel après son décès.
                    <br/>Ces droits s'exercent soit par courrier électronique adressé à : informatique-libertes@nexity.fr ou par courrier postal à l'attention du Délégué à la Protection des Données (DPO) - Nexity SA - 19 Rue de Vienne - TSA 50029 - 75801 PARIS Cedex 08, accompagné d’une copie d’un titre d’identité.
                    <br/>Vous pouvez également adresser une réclamation auprès de la Commission Nationale de l'Informatique et des Libertés - 3 place de Fontenoy - TSA 80715 - 75334 PARIS Cedex 08.
                    <br/>Le responsable de traitement prend l’ensemble des mesures nécessaires à la sécurité et à l’intégrité des données. Il met en place un signalement des incidents ayant un impact sur les données à votre profit dans le délai légal à compter de sa connaissance dudit incident.
                    <br/>A toute fin utile, nous vous précisons que la Société se réserve le droit de procéder à l’archivage des données personnelles qu’elle a pu être amenée à collecter en exécution des présentes, pour la durée de prescription des actions en responsabilité. Dans ce cas, les données archivées seront stockées sur un serveur sécurisé, et pourra faire l’objet d’un accès exclusivement dans le cadre d’un contentieux dont la résolution nécessite la communication judiciaire desdites données.
                </p>
                <p>14.	Cookies
                    <br/>Nexity Solutions Digitales met en œuvre pour ce site une technologie qui enregistre des cookies sur votre terminal. Ces cookies ont pour finalité exclusive de permettre ou faciliter la navigation sur ce site et sont donc indispensable à sa bonne utilisation. Vous pouvez intervenir sur ces cookies en modifiant le paramétrage de votre navigateur. Toutefois, la suppression des cookies pourrait empêcher l’Utilisateur de naviguer correctement sur le Site et empêcher l’accès à certaines de ses fonctionnalités. Les cookies sont enregistrés sur votre terminale pour une durée de 6 mois.
                </p>
                <p>15.	Liens hypertexte
                    <br/>L'Application ne contient pas des liens hypertextes permettant d'accéder directement à d'autres contenus.
                </p>
                <p>16.	Résiliation - suppression
                    <br/>A l’initiative de l’Utilisateur : Clôture du compte Utilisateur
                    <br/>Tout Utilisateur qui clôture son Compte Utilisateur perd le droit d’accès aux Services. Toute demande de suppression de compte doit faire l’objet d’une demande auprès de la Société, par email à l’adresse flexaccess@nexity.fr.
                    <br/>Suppression ou suspension à l’initiative de la Société
                    <br/>Sans préjudice des autres cas de suspension prévus dans les Conditions Générales, la Société se réserve le droit d’interrompre de façon temporaire ou définitive tout ou partie des services pour des raisons liées à la sécurité du service ou dans les cas prévus à l’article 7 ou en cas de manquement avéré par l’Utilisateur aux présentes Conditions Générales d’Utilisation.
                </p>
                <p>17.	Conséquences liées à la suppression ou de la suspension du compte
                    <br/>La suppression ou la suspension du Compte entraîne l’impossibilité d’accéder aux Services.
                </p>
                <p>18.	Permanence
                    <br/>La nullité d’une clause quelconque des Conditions Générales d'Utilisation n’affecte pas la validité des autres clauses ; elle se poursuit en l’absence du dispositif annulé sauf si la clause annulée rend la poursuite des relations contractuelles impossible ou déséquilibrée par rapport aux conventions initiales.
                </p>
                <p>19.	Intitulé des conditions
                    <br/>Les intitulés portés en tête de chaque article ne servent qu’à la commodité de la lecture et ne peuvent en aucun cas être le prétexte d’une quelconque interprétation ou dénaturation des clauses sur lesquelles ils portent. En cas de difficulté d'interprétation ou de contradiction entre le contenu d'une clause et son titre, ce dernier est réputé non écrit.
                </p>
                <p>20.	Droit applicable et juridiction compétente
                    <br/>Les présentes Conditions Générales sont soumises au droit français. Toute contestation de quelque nature qu'elle soit, relative à l'application des présentes Conditions Générales et à l'utilisation et à la consultation du Site, doit, à défaut d'accord amiable entre les parties, être portée devant les Tribunaux français seuls compétents.
                </p>
            </div>
            <FACheckbox label="J'accepte les présentes conditions générales d'utilisation" name="accept" type="checkbox" id="accept"/>
        </div>
    );
}
export { RegisterStepCgu };
