import React, { useState, useEffect } from 'react';
import ContactCard from "./ContactCard";
import { ContactData } from "../Types";
import "./Contacts.scss"
import { BigButton } from '../common/FAButtons';
import { PlusIcon, SearchIcon} from '../common/Icons';
import ContactForm from './ContactForm';
import { FASimpleTextField } from '../common/form/FATextField';
import { sortedByLastName } from '../Utils';
import {Confirm} from '../common/Confirm';
import { t } from '../../languages/Translations'
import {fetchContacts, deleteContact, createContact, updateContact } from "../../api/contact";
import {userContext} from "../App";

function Contacts({ value: { setLoading, displayToaster } }) {
  const [search, setSearchValue] = useState("");

  // load contact list
  useEffect(() => {
        // load contacts if necessary
        if(userContext.contacts == null || userContext.contacts.length == 0) {
          setLoading(true);
          fetchContacts().then((res) => {
            userContext.contacts = res.contacts;
            setLoading(false);
          }).catch(e => {
            setLoading(false)
            displayToaster({message: t('contacts_screen.fetch_failed'), error: true})
          });
        }
        userContext.contacts.map(contact => {
          contact.selected = false;
        })
  }, []);

  function updateSearch(search: string) {
    setSearchValue(search);
  }

  function saveContact(contact: ContactData) {
    // edit mode
    setLoading(true);
    if(contact.code !== null && contact.code !== "") {
      updateContact(contact)
          .then((res) => {
            setLoading(false);
            userContext.contacts[userContext.contacts.findIndex(obj => obj.code === contact.code)] = res.contact;
            setSearchValue("");
            displayToaster({ message:t("contacts_screen.edit_succeed"), error: false })
          })
          .catch(e => {
              setLoading(false);
              let msg = t("bo_errors."+e);
              if(msg == null || msg.length == 0) msg = t("contacts_screen.edit_error")+" ("+e+")"
              displayToaster({ message: msg, error: true  })
          })
    }
    // create mode
    else {
      createContact(contact)
          .then((res) => {
            setLoading(false);
            userContext.contacts.push(res.contact)
            setSearchValue("");
            displayToaster({ message:t("contacts_screen.create_succeed"), error: false })
          })
          .catch(e => {
              setLoading(false);
              const code = "codes.err"+e;
              let msg = t(code);
              if(msg == null || msg.length == 0) msg = t("contacts_screen.create_error")+" ("+e+")"
              displayToaster({ message: msg, error: true  })
          })
    }
  }

  function removeContact(contact: ContactData) {
    setLoading(true);
    deleteContact(contact)
        .then(() => {
          setLoading(false);
          userContext.contacts.splice(userContext.contacts.findIndex(obj => obj.code === contact.code), 1)
          displayToaster({ message:t("contacts_screen.delete_succeed"), error: false })
        })
        .catch(e => {
          setLoading(false);
          const code = "codes.err"+e;
          let msg = t(code);
          if(msg == null || msg.length == 0) msg = t("contacts_screen.delete_error")+" ("+e+")"
          displayToaster({ message: msg, error: true  })
        })
  }

  return (
    <div className="content-padding contact">
      <div className="contact-header">
        <h4>{userContext.contacts.length + (userContext.contacts.length > 1 ?
          " " + t('contacts_screen.contacts_number_label')
          : " " + t('contacts_screen.contact_number_label'))}</h4>
        <div className="search">
          <FASimpleTextField
            label=""
            name="searchContact"
            type="text"
            value={search}
            icon={<SearchIcon />}
            handleChange={updateSearch}
          />
        </div>
      </div>
      <SortContacts search={search} contacts={userContext.contacts} saveContact={saveContact} deleteContact={removeContact} />
    </div>
  )
}

const SortContacts: React.FC<any> = (props: { search: string, contacts: Array<ContactData>, saveContact, deleteContact }) => {

  const contacts = props.contacts;
  const [displayModal, setDisplayModal] = useState(false);
  const [displayConfirm, setDisplayConfirm] = useState(false);

  let contactData: ContactData = {
    code: '',
    nameFirst: '',
    nameLast: '',
    email: '',
    company: ''
  };
  const [contact, setContact] = useState(contactData);

  //Group contact by first letter
  const sortedContacts: ContactData[] = sortedByLastName(contacts, props.search, false);
  const groupedByInitial = sortedContacts.reduce((acc, obj) => {
    if (obj.nameLast) {
      acc[obj.nameLast.trim().toUpperCase().charAt(0)] = acc[obj.nameLast.trim().toUpperCase().charAt(0)] || [];
      acc[obj.nameLast.trim().toUpperCase().charAt(0)].push(obj);
    } else if (obj.email) {
      acc[obj.email.trim().toUpperCase().charAt(0)] = acc[obj.email.trim().toUpperCase().charAt(0)] || [];
      acc[obj.email.trim().toUpperCase().charAt(0)].push(obj);
    }
    return acc;
  }, {});

  function formModalDisplay() {
    if (props.search.includes("@")) {
      contactData.email = props.search;
    } else {
      contactData.nameFirst = props.search;
    }
    setContact(contactData);
    setDisplayModal(true);
  }

  function formModalHide() {
    setDisplayModal(false);
  }

  function handleSave(contact: ContactData) {
    setContact(contact);
    setDisplayModal(true);
  }

  function saveContact(contact: ContactData) {
    props.saveContact(contact);
    setDisplayModal(false);
  }

  function handleDelete(contact: ContactData) {
    setContact(contact);
    setDisplayConfirm(true);
  }

  function deleteContact() {
    setDisplayConfirm(false);
    props.deleteContact(contact);
  }

    return (
      <div className="contact-list result">
        {
          Object.keys(groupedByInitial).map(initial =>
            <div key={initial}>
              <div className="contacts-separator">
                <span>{initial}</span>
                <div />
              </div>
              {
                sortedContacts.map(c => {
                  if ((c.nameLast && c.nameLast.trim().toUpperCase().charAt(0) === initial) || (!c.nameLast && c.email && c.email.trim().toUpperCase().charAt(0) === initial))
                    return <ContactCard contactData={c} key={c.code} editButtons={true} handleChange={() => { }} handleDelete={() => handleDelete(c)} handleEdit={() => handleSave(c)} />
                  else return null
                })
              }
            </div>
          )
        }
        <BigButton className="create-button" type="button" label={t('common.new_contact_button')} color="orange" size="big" handleClick={formModalDisplay} icon={<PlusIcon color="white" size="small" />} />
        {displayModal ? <ContactForm contact={contact} handleClose={formModalHide} handleSubmit={saveContact} /> : null}
        {displayConfirm ? <Confirm title={t('common.confirmation')} content={t('contacts_screen.delete_confirmation')} handleConfirm={() => deleteContact()} handleCancel={() => setDisplayConfirm(false)} /> : null}
      </div>

    )
  //}
}

export default Contacts;
