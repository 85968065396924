import React from "react";
import './Register.scss';
import {t} from "../../languages/Translations";
import {BigButton} from "../common/FAButtons";

function RegisterConfirm({value:{ email, message }}) {
    function login() {
        window.location.href = "/login?e="+email;
    }
    function retry() {
        window.location.href = "/register";
    }
        if(message == null) {
            return (
                <div className="content">
                    <div className="content-padding">
                        <div className="title-container">
                            <h2>{t("register_screen.title_succeed")}</h2>
                        </div>
                        <p>&nbsp;</p>
                        <p><span className="text-ellipsis">{t("register_screen.confirmation_succeed_header")}</span></p>
                        <p><span
                            className="text-ellipsis">{t("register_screen.confirmation_succeed_detail").replace("%email%", email)}</span>
                        </p>
                        <div className="text-ellipsis">{t("register_screen.confirmation_links")}</div>
                        <div>
                            <li>< a href="https://app.flexaccess.fr/doc/guide_flexaccess.pdf">Documentation utilisateur</a></li>
                        </div>
                        <div>
                            <li>< a href="mailto:flexaccess@nexity.fr">Service Clients</a></li>
                        </div>
                        <p><span className="text-ellipsis">{t("register_screen.confirmation_succeed_footer")}</span></p>
                        <br/><br/>
                        <BigButton type="submit" label={t('login_screen.connexion_button')} color="orange" size="big"
                                   handleClick={login}/>
                    </div>
                </div>
            );
        }
        else {
            return (
                <div className="content">
                    <div className="content-padding">
                        <div className="title-container">
                            <h2>{t("register_screen.title_failed")}</h2>
                        </div>
                        <p>&nbsp;</p>
                        <p><span className="text-ellipsis">{t("register_screen.confirmation_failed_header")}</span></p>
                        <p><span className="text-ellipsis"><b>{message}</b></span></p>
                        <p><span className="text-ellipsis">{t("register_screen.confirmation_failed_footer")}<a href="mailto:flexaccess@nexity.fr?subject=Inscription FlexBooking échouée">flexaccess@nexity.fr</a></span></p>
                        <br/>
                        <div><button onClick={retry} className="meeting-button cancel-button">{t('register_screen.back_button')}</button></div>
                    </div>
                </div>
            );
        }
}

export { RegisterConfirm };
