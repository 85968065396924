import {currentToken } from "../components/Utils";
import {ContactData} from "../components/Types";

/**
 *
 */
export async function fetchContacts() {
    const options:RequestInit = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            token: currentToken()
        })
    }
    return fetch(`${process.env.REACT_APP_PUBLIC_URL}/member/102/contact/fetch`, options).then(async (r) => {
        if(r.status !== 200) throw "http:"+r.status;
        let json = await r.json();
        if(json.header.status === 0) throw json.header.error;
        return json;
    });
}

/**
 *
 * @param nameFirst
 * @param nameLast
 * @param email
 * @param company
 */
export async function createContact(contact: ContactData) {
    const options:RequestInit = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token: currentToken(),
        contact
      })
    }
    return fetch(`${process.env.REACT_APP_PUBLIC_URL}/member/102/contact/add`, options).then(async (r) => {
        if(r.status !== 200) throw "http:"+r.status;
        let json = await r.json();
        if(json.header.status === 0) throw json.header.error;
        return json;
    });
}

/**
 *
 * @param code
 * @param nameFirst
 * @param nameLast
 * @param email
 * @param company
 */
export async function updateContact(contact: ContactData) {
    const options:RequestInit = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            token: currentToken(),
            contact
        })
    }
    return fetch(`${process.env.REACT_APP_PUBLIC_URL}/member/102/contact/update`, options).then(async (r) => {
        if(r.status !== 200) throw "http:"+r.status;
        let json = await r.json();
        if(json.header.status === 0) throw json.header.error;
        return json;
    });
}

/**
 *
 * @param code
 */
export async function deleteContact(contact: ContactData) {
    const options:RequestInit = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            token: currentToken(),
            contact
        })
    }
    return fetch(`${process.env.REACT_APP_PUBLIC_URL}/member/102/contact/delete`, options).then(async (r) => {
        if(r.status !== 200) throw "http:"+r.status;
        let json = await r.json();
        if(json.header.status === 0) throw json.header.error;
        return json;
    });
}
