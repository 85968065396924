import React, { useState, useEffect } from 'react';
import FASelectField from '../common/form/FASelectField';
import { CheckIcon } from '../common/Icons';
import { useFormikContext } from 'formik';
import { searchRoom } from '../../api/rooms';
import { floorLabel } from '../Utils';
import { RoomData } from '../Types';
import { t } from '../../languages/Translations';
import {  } from '../App'

function MeetingStepRoom({value:{ setLoading, displayToaster }}) {
    const [allRooms, setAllRooms] = useState(Array<RoomData>());
    const [roomsFiltered, setRoomsFiltered] = useState(allRooms);
    const [close, setClose] = useState(true);
    //
    const [floorsAvailable, setFloorsAvailable] = useState(Array<number>());
    const [locationNamesAvailable, setLocationAvailable] = useState(Array<string>());
    const [roomNamesAvailable, setRoomAvailable] = useState(Array<string>());
    //
    const [searchExecuted, setSearchExecuted] = useState(false)
    const formik: any = useFormikContext();
    useEffect(() => {
        // reset filter criterias
        formik.setFieldValue("reservation.room.location.label", '', false);
        formik.setFieldValue("reservation.room.floor", '', false);
        formik.setFieldValue("reservation.room.label", '', false);
        // search rooms
        setLoading(true);
        searchRoom(formik.values)
                .then((rooms) => {
                    setSearchExecuted(true);
                    //Sort rooms
                    const sortedRooms = rooms.sort((a, b) => {
                        if (a.label && b.label && a.label > b.label) return 1;
                        if (a.label && b.label && a.label < b.label) return -1;
                        return 0
                    });
                    //Check if already room selected in context
                    if (formik.values.reservation && formik.values.reservation.room && formik.values.reservation.room.code) {
                        const updatedRes = sortedRooms.map(room => {
                            if (room.code === formik.values.reservation.room.code) {
                                room.selected = true
                            }
                            return room;
                        })
                        setAllRooms(updatedRes);
                        setRoomsFiltered(updatedRes);
                    } else {
                        setAllRooms(sortedRooms);
                        setRoomsFiltered(sortedRooms);
                    }
                    populateDropdowns(sortedRooms);
                    setLoading(false);
            }).catch(e => {
                setLoading(false);
                displayToaster({ message: t("new_meeting_form.errors.search_room_failed")+" ("+e+")", error: true });
        });

        function populateDropdowns(rooms: Array<RoomData>) {
            var floors = Array<number>();
            var locationNames = Array<string>();
            var roomNames = Array<string>();

            rooms.forEach(room => {
                if (room.floor !== undefined && floors.indexOf(room.floor) === -1) {
                    floors.push(room.floor);
                }
                if (room.location && locationNames.indexOf(room.location?.label) === -1) {
                    locationNames.push(room.location?.label);
                }
                if (room.name && roomNames.indexOf(room.label) === -1) {
                    roomNames.push(room.label);
                }
            });

            setFloorsAvailable(sort(floors));
            setLocationAvailable(sort(locationNames));
            setRoomAvailable(sort(roomNames));
        }
        // eslint-disable-next-line
    }, [])

    function sort(array: Array<any>) {
        return array.sort((a, b) => {
            if (a > b) return 1;
            if (a < b) return -1;
            return 0
        });
    }

    function toogleSelected(id: string) {
        const updatedRooms = allRooms.map(room => {
            if (room.code === id) {
                if (room.selected) {
                    room.selected = false;
                    formik.setFieldValue('reservation.room.name', '');
                    formik.setFieldValue('reservation.room.label', '');
                    formik.setFieldValue('reservation.room.floor', '');
                    formik.setFieldValue('reservation.room.maxCapacity', '');
                    formik.setFieldValue('reservation.room.address', '');
                    formik.setFieldValue('reservation.room.code', '');
                    formik.setFieldValue('reservation.room.thumbnailUrl', room.thumbnailUrl);
                } else {
                    room.selected = true
                    formik.setFieldValue('reservation.room.name', room.name);
                    formik.setFieldValue('reservation.room.label', room.label);
                    formik.setFieldValue('reservation.room.floor', room.floor);
                    formik.setFieldValue('reservation.room.maxCapacity', room.maxCapacity);
                    formik.setFieldValue('reservation.room.address', room.address);
                    formik.setFieldValue('reservation.room.location', room.location);
                    formik.setFieldValue('reservation.room.code', room.code);
                    formik.setFieldValue('reservation.room.thumbnailUrl', room.thumbnailUrl);
                }
            } else {
                room.selected = false;
            }
            return room;
        });
        setAllRooms(updatedRooms);
    }

    function toggleScroll() {
        setClose(!close);
    }
    //Update rooms depending on filter
    useEffect(() => {
        // -------
        function updateRoomList(location?: string, floor?: number, room?: string) {
            let list = allRooms;
            if (location && location.length > 0 && location !== t("new_meeting_form.placeholders.room_building_select")) {
                list = filterRoomsByLocation(list, location);
            }
            if (floor && !isNaN(Number(floor))) {
                list = filterRoomsByFloor(list, Number(floor));
            }
            if (room && room.length > 0 && room !== t("new_meeting_form.placeholders.room_select")) {
                list = filterRoomsByName(list, room);
            }
            return list;
        };
        // -------
        function filterRoomsByLocation(array: Array<RoomData>, value: string) {
            return array.filter(room => room.location?.label === value)
        }
        // -------
        function filterRoomsByFloor(array: Array<RoomData>, value: number) {
            return array.filter(room => room.floor === value)
        }
        // -------
        function filterRoomsByName(array: Array<RoomData>,  value: string) {
            return array.filter(room => room.label === value)
        }
        // -------

        const list = updateRoomList(formik.values.info.location, formik.values.info.floor, formik.values.info.room);
        setRoomsFiltered(list);

        // eslint-disable-next-line 
    }, [formik.values])

    return (
        <div className="meeting-content overflow full-width">
            <div className="block form">
                    <FASelectField
                        label={t('new_meeting_form.labels.room_building_select')}
                        name="info.location"
                        type="text"
                        placeholder={t('new_meeting_form.placeholders.room_building_select')}
                        defaultValue={null}
                        values={locationNamesAvailable}
                        enabled={locationNamesAvailable.length > 1}
                    />
                    <FASelectField
                        label={t('new_meeting_form.labels.room_floor_select')}
                        name="info.floor"
                        type="number"
                        defaultValue={null}
                        placeholder={t('new_meeting_form.placeholders.room_floor_select')}
                        values={floorsAvailable}
                        enabled={floorsAvailable.length > 1}
                    />
                    <FASelectField
                        label={t('new_meeting_form.labels.room_select')}
                        name="info.room"
                        type="text"
                        placeholder={t('new_meeting_form.placeholders.room_select')}
                        defaultValue={null}
                        values={roomNamesAvailable}
                        enabled={roomNamesAvailable.length > 1}
                    />
            </div>
                <div className={`block room-list-container ${close ? "close" : ""}`}>
                    <div className="limited-width with-padding">
                        {
                            (locationNamesAvailable.length > 1 || floorsAvailable.length > 1 || roomNamesAvailable.length > 1)
                            && <button type="button" onClick={toggleScroll}>{close ? t('new_meeting_form.actions.more_criteria') : t('new_meeting_form.actions.less_criteria')}</button>
                        }
                            <RoomList rooms={roomsFiltered} handleSelection={toogleSelected}
                                      searchExecuted={searchExecuted}/>

                    </div>
                </div>
        </div>
    );
}

const RoomList: React.FC<any> = (props: { rooms: Array<RoomData>, handleSelection, searchExecuted }) => {
    const [rooms, setRooms] = useState(props.rooms);
    function toogleSelected(id: string) {
        props.handleSelection(id);
    }

    useEffect(() => {
        if (rooms !== props.rooms) {
            setRooms(props.rooms);
        }
    }, [rooms, props.rooms])

    if (!props.searchExecuted) {
        return (
            <>
                <div className="room-list-header ">
                    <h3>{t('new_meeting_form.step_titles.searching_rooms')}</h3>
                </div>
            </>
        );
    }
    else {
        if (rooms.length === 0) {
            return (
                <>
                    <div className="room-list">
                        <h3>{t('new_meeting_form.no_room_found')}</h3>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <div className="room-list-header ">
                        <h3>{rooms.length > 1 ? rooms.length + " " + t('new_meeting_form.rooms_number_found_label') : rooms.length + " " + t('new_meeting_form.room_number_found_label')}</h3>
                    </div>
                    <div className="room-list">
                        {rooms.map(room =>
                            <RoomCard data={room} key={room.code} handleChange={toogleSelected}/>
                        )}
                    </div>
                    <div style={{"height": "50px"}}></div>
                </>
            );
        }
    }
}

const RoomCard: React.FC<any> = (props: { data: RoomData, handleChange }) => {
    let thumbUrl = props.data.thumbnailUrl;
    if(thumbUrl == null || thumbUrl.length === 0) thumbUrl = "/img/room_default.png";
    else {
        if(!thumbUrl.toLowerCase().includes("http"))
            thumbUrl = process.env.REACT_APP_PUBLIC_URL+"/img/"+thumbUrl;
    }

    const style = {
        background: "url('" + thumbUrl + "')",
        backgroundSize: 'cover'
    }
    return (
        <div className={`room-card ${props.data.selected ? "selected" : ""}`} id={props.data.code} onClick={() => props.handleChange(props.data.code)}>
            <div className="room-image" style={style}>
                <div className="room-image-layer-container">
                    <div className="room-image-layer"></div>
                    <CheckIcon value="white" />
                </div>
            </div>
            <div className="room-info">
                <h4>{props.data.label}</h4>
                <div className="capacity"><span>{props.data.maxCapacity && props.data.maxCapacity > 1 ? props.data.maxCapacity + " " + t('common.people_plur_number_label') : props.data.maxCapacity + " " + t('common.people_number_label')}</span></div>
                <div className="other"><span>{props.data.location?.label} · {floorLabel(props.data.floor)}</span></div>
            </div>
        </div>
    );
}

export default MeetingStepRoom;
