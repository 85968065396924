import React, { useState, useEffect, useCallback } from 'react';
import './Toast.scss';

type Toast = {
  id: number,
  title: string,
  message: string,
  titleColor: string,
  icon: any
}

const Toast = (props: { toastData: Array<Toast>, autoDelete: boolean, autoDeleteTime?: number }) => {
  const { toastData, autoDelete, autoDeleteTime } = props;
  const [toastList, setList] = useState(toastData);

  const deleteToast = useCallback(
    () => {
      toastList.shift();
      setList([]);
    },
    [toastList]
  );

  useEffect(() => {
    setList(toastData);
  }, [toastData, toastList]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (autoDelete && toastData.length && toastList.length) {
        deleteToast();
      }
    }, autoDeleteTime);

    return () => {
      clearInterval(interval);
    }
  }, [autoDelete, autoDeleteTime, deleteToast, props, toastData.length, toastList.length]);
  //TODO P3: Add icon
  return (
    <div className={`notification-container`}>
      {
        toastList.map((toast, i) =>
          <div
            key={i}
            className={`notification toast`}>
            <div className="notification-image">
              {toast.icon}
            </div>
            <div className="notification-text">
              <p style={{ color: toast.titleColor }}>{toast.title}</p>
              <p>
                {toast.message}
              </p>
            </div>
            <button onClick={() => deleteToast()}>
              X
            </button>
          </div>
        )
      }
    </div>
  )
}

export default Toast;