import React from 'react';
import './AttendeesList.scss';
import { userContext } from '../App';
import ContactCard from "../contacts/ContactCard";
import { sortedByLastName } from '../Utils';
import { fetchContacts } from "../../api/contact";
import { AttendeeData } from "../Types";

function AttendeesList(data: { attendees: Array<AttendeeData>}) {

  const contacts = data.attendees.flatMap(attendee => {
    if(userContext.contacts == null || userContext.contacts.length == 0) {
      fetchContacts().then((res) => {
        userContext.contacts = res.contacts;
      });
    }
    const cs = userContext.contacts.filter((contact) => contact.code === attendee.ref || contact.code === attendee.code);
    if (cs.length > 0) return cs[0];
    else return [];
  });

  return (
    <div className="attendees-list">
      { sortedByLastName(contacts, "", false).map(contactData =>
         <ContactCard key={contactData.code} contactData={contactData} editButtons={false} handleChange={() => { }} handleEdit={() => { }} handleDelete={() => { }} />
      )}
    </div>
  );
}

export default AttendeesList;
