import React, { useState, useEffect, useContext } from 'react';
import {FASimpleTextField, FATextField} from '../common/form/FATextField';
import {SearchIcon, EditIcon, PlusIcon} from '../common/Icons';
import FASwitchField from '../common/form/FASwitchField';
import { ContactData } from '../Types';
import ContactCard from '../contacts/ContactCard';
import { userContext } from '../App';
import { useFormikContext } from 'formik';
import { BigButton } from '../common/FAButtons';
import ContactForm from '../contacts/ContactForm';
import { sortedByLastName } from '../Utils';
import { t } from '../../languages/Translations';
import {createContact, fetchContacts} from "../../api/contact"

function MeetingStepAttendees({value:{ setLoading, displayToaster }}){
    const [search, setSearch] = useState("");
    const [onlyAttendee, setOnlyAttendee] = useState(false);
    const formik: any = useFormikContext();

    useEffect(() => {
        if(userContext.contacts == null || userContext.contacts.length == 0) {
            setLoading(true);
            fetchContacts().then((res) => {
                userContext.contacts = res.contacts
                //etContacts(userContext.contacts);
                setLoading(false);
            }).catch(e => {
                setLoading(false)
                displayToaster({message: t('contacts_screen.fetch_failed'), error: true })
            });
        }
        //else setContacts(userContext.contacts);
    }, []);

    // Set selected for contacts if participants values is not empty
    if (formik.values.reservation.attendees) {
        formik.values.reservation.attendees.forEach(participant => {
            const cs = userContext.contacts.filter(contact => contact.code === participant.code);
            if (cs.length === 1) {
                cs[0].selected = true;
            }
        });
    }

    /*function filterContact() {
        setContacts(sortedByLastName(userContext.contacts, search, onlyAttendee));
    }*/

    function toogleAttendeeFilter() {
        setOnlyAttendee(!onlyAttendee);
    }

    function updateSearch(search: string) {
        setSearch(search)
        //filterContact()
    }

    function toogleSelectContact(c: ContactData) {
        const cs = userContext.contacts.filter(contact => contact.code === c.code);
        if (cs.length === 1) {
            cs[0].selected = !cs[0].selected;
        }
        formik.setFieldValue('reservation.attendees', userContext.contacts.filter(p => p.selected));
    }

    function saveContact(contact: ContactData) {
        setLoading(true);
        createContact(contact)
            .then((res) => {
                setLoading(false);
                userContext.contacts.push(res.contact)
                contact.selected = true;
                setSearch("");
                displayToaster({ message:t("contacts_screen.create_succeed"), error: false })
            })
            .catch(e => {
                setLoading(true);
                displayToaster({ message: t("contacts_screen.create_error")+" ("+e+")", error: true })
            })
    }

    return (
        <div className="meeting-content limited-width overflow">
            {
                userContext.contacts.length > 0 &&
                <div className="search">
                    <FASimpleTextField
                        label=""
                        name="search"
                        type="text"
                        value={search}
                        icon={<SearchIcon/>}
                        handleChange={updateSearch}
                    />
                </div>
            }
            <div className="result">
                { userContext.contacts.length > 0 && <div className="switch-control">
                    <span className="switch-label">{t('new_meeting_form.actions.show_attendees_only')}</span>
                    <FASwitchField handleChange={toogleAttendeeFilter}/>
                </div>
                }
                <ContactList search={search} contacts={userContext.contacts} addContact={saveContact} toogleSelectContact={toogleSelectContact} selected={onlyAttendee} />
            </div>
        </div>
    );
}

const ContactList: React.FC<any> = (props: { search: string, contacts: Array<ContactData>, addContact, toogleSelectContact, selected }) => {
    const contacts = props.contacts;
    const [displayModal, setDisplayModal] = useState(false);

    let contactData: ContactData = {
        code: '',
        nameFirst: '',
        nameLast: '',
        email: '',
        company: ''
    };

    const [contact, setContact] = useState(contactData);
    const sortedContacts: ContactData[] = sortedByLastName(contacts, props.search, props.selected);

    function toogleSelected(code: string) {
        const cont = contacts.filter(c => code === c.code)
        if (cont.length > 0) {
            props.toogleSelectContact(cont[0]);
        }
    }

    function toogleDisplayModal() {
        if (props.search.includes("@")) {
            contactData.email = props.search;
        } else {
            contactData.nameFirst = props.search;
        }
        setContact(contactData);
        setDisplayModal(true);
    }

    function handleCloseModal() {
        setDisplayModal(false);
    }

    function createContact(contact: ContactData) {
        props.addContact(contact);
        setDisplayModal(false);
    }

    if (contacts.length === 0) {
        return (
            <div className="contact-list no-result">
                <h3>Aucun contact trouvé</h3>
                <BigButton type="button" label={t('common.new_contact_button')} color="orange" size="big" handleClick={toogleDisplayModal} icon={<EditIcon color="white" size="big" />} />
                {displayModal ? <ContactForm contact={contact} handleClose={handleCloseModal} handleSubmit={createContact} /> : null}
            </div>
        );
    } else {
        return (
            <div className="contact-list">
                {    sortedByLastName(sortedContacts, "", props.selected).map(contact =>
                    <ContactCard horizontal={false} contactData={contact} key={contact.code} editButtons={false} handleChange={toogleSelected} handleEdit={() => { }} handleDelete={() => { }} />
                )}
                <div className="contact-list no-result">
                    <BigButton type="button" label={t('common.new_contact_button')} color="orange" size="big" handleClick={toogleDisplayModal} icon={<PlusIcon color="white" size="small" />} />
                </div>
                {displayModal ? <ContactForm contact={contact} handleClose={handleCloseModal} handleSubmit={createContact} /> : null}
            </div>
        );
    }
}

export default MeetingStepAttendees;
