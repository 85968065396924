import React from 'react';
import './FASwitchField.scss';

const FASwitchField = ({ handleChange, ...props }: any) => {
    return (
        <label className="switch">
            <input type="checkbox" onChange={() => handleChange()} />
            <span className="slider round"></span>
        </label>
    );
}

export default FASwitchField;