import React from 'react';
import { useField } from 'formik';
import './FACheckbox.scss';

const FACheckbox = ({ label, subLabel, id, icon, ...props}: any) => {
  const [field, meta] = useField(props);

  return (
    <div className="fa-checkbox-container">
      {meta.touched && meta.error ? (<span className="error-label">{meta.error}</span> ) : icon}
      <input {...field} {...props} id={id} className={meta.touched && meta.error ? 'error' : ''} checked={(field.value)}/>
      <label htmlFor={id} ><span>{label}</span></label>
    </div>
  );
}

export default FACheckbox;
