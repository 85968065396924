import {css} from "@emotion/react";

export const STEP_TITLES = [
    "Informations générales",
    "Sélection de l'espace",
    "Inviter des participants",
    "Finalisation de la réservation",
    "Workshop FlexAccess",
]

export const STEP_BUTTONS = [
    "Choisir une salle",
    "Inviter des participants",
    "Récapitulatif",
    "Valider la réservation",
]

export const STEP_REGISTER_TITLES = [
    "Conditions générales d'utilisation",
    "Vos informations",
    "Confirmation d'inscription"
]

export const STEP_REGISTER_BUTTONS = [
    "Suivant",
    "S'inscrire",
    "Se connecter"
]

export const SPINNER_CSS = css`
      display: block;
      margin: 0 auto;
      border-color: #f35c2c;
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    `;