import React from 'react';
import ReactDOM from 'react-dom';
import {Formik} from 'formik';
import { FATextField } from '../common/form/FATextField';
import { BigButton } from '../common/FAButtons';
import { ForwardArrowIcon } from '../common/Icons';
import './Contacts.scss';
import { ContactData } from '../Types';
import * as Yup from 'yup';
import { t } from '../../languages/Translations'

const ContactForm = (props) => {
    const contact = props.contact ? props.contact : {};
    const initialValues: ContactData = {
        code: contact.code ? contact.code : '',
        nameFirst: contact.nameFirst ? contact.nameFirst : '',
        nameLast: contact.nameLast ? contact.nameLast : '',
        email: contact.email ? contact.email : '',
        company: contact.company ? contact.company : '',
        selected: false,
    }
    const title = t('contacts_screen.form.title');

    let el = document.body;
    if (document.getElementById('content')) {
        el = document.getElementById('content')!;
    }

    return (
        ReactDOM.createPortal(
            <div className="contact-main show">
                <div className="contact-modal">
                    <h2>{title}</h2>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={(values) => {
                            props.handleSubmit(values);
                        }}
                        validationSchema={Yup.object({
                            email: Yup.string().matches(/^[a-zA-Z0-9.-_]+@[a-zA-Z0-9-_]+(?:\.[a-zA-Z0-9-]+)*$/, " ").required(t('new_meeting_form.errors.mandatory_field')).email(t('login_screen.email_error')),
                            nameFirst: Yup.string().matches(/^[a-zA-Z0-9\-\._àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ' ]+$/, " "),
                            nameLast: Yup.string().matches(/^[a-zA-Z0-9\-\._àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ' ]+$/, " "),
                            company: Yup.string().matches(/^[a-zA-Z0-9\-\._àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ' ]+$/, " ")
                        })}
                    >
                        {formik => (
                            <form onSubmit={formik.handleSubmit}>
                                <input type="hidden" name="id" value={initialValues.code} />
                                <FATextField
                                    label={t('contacts_screen.form.firstname_label')}
                                    name="nameFirst"
                                    type="text"
                                />
                                <FATextField
                                    label={t('contacts_screen.form.lastname_label')}
                                    name="nameLast"
                                    type="text"

                                />
                                <FATextField
                                    label={t('contacts_screen.form.email_label')}
                                    name="email"
                                    type="text"
                                />
                                <FATextField
                                    label={t('contacts_screen.form.company_label')}
                                    name="company"
                                    type="text"
                                />
                                <div className="contact-button">
                                    <span className="cancel" onClick={props.handleClose} >{t('common.cancel')}</span>
                                    <BigButton type="submit" label="Enregistrer" color="orange" size="big" icon={<ForwardArrowIcon value="white" />} handleClick={() => { }}  />
                                </div>
                            </form>
                        )}
                    </Formik>

                </div>
            </div>, el
        )
    );
}

export default ContactForm;
