import { ContactData, UserData } from "./Types";
import Cookies from 'universal-cookie';

/**
 * Return the last retreived token (stored in local cookie)
 */
export function currentToken() {
    let token = new Cookies().get("token", );
    if(token != null && token.length > 0) return token;
    return null;
}

export function sortedByLastName(contacts: Array<ContactData>, filter: string, selected: boolean) {
    let filteredContacts: ContactData[] = [];
    // apply filter if necessary
    if(filter.length > 0) {
        filteredContacts = filterContacts(contacts, filter);
    }
    else
        filteredContacts = contacts;
    // apply selected if necessary
    if(selected)
        filteredContacts = filteredContacts.filter(c => c.selected);

    // return sorted filters list
    return filteredContacts.sort(function (a, b) {
        if (a.nameLast && b.nameLast) {  //Lastnames
          const anameLast = a.nameLast.trim().toLowerCase();
          const bnameLast = b.nameLast.trim().toLowerCase();
          if (anameLast < bnameLast) return -1;
          if (anameLast > bnameLast) return 1;
    
        } else if (!a.nameLast && b.nameLast && a.email) { //mail and lastname
          const aAddress = a.email.trim().toLowerCase();
          const bnameLast = b.nameLast.trim().toLowerCase();
          if (aAddress > bnameLast) return 1;
          if (aAddress < bnameLast) return -1;
    
        } else if (a.nameLast && !b.nameLast && b.email) { //lastname and mail
          const anameLast = a.nameLast.trim().toLowerCase();
          const bAddress = b.email.trim().toLowerCase();
          if (anameLast > bAddress) return 1;
          if (anameLast < bAddress) return -1;
    
        } else if (!a.nameLast && !b.nameLast && a.email && b.email) { //mails
          const aAddress = a.email.trim().toLowerCase();
          const bAddress = b.email.trim().toLowerCase();
          if (aAddress > bAddress) return 1;
          if (aAddress < bAddress) return -1;
        }
        return 0;
    });
}

/**
 *
 * @param search
 * @param contacts
 */
export function filterContacts(contacts: Array<ContactData>, search: string) {
    const cList = Array<ContactData>();
    contacts.forEach(c => {
        if(c.nameFirst && c.nameFirst.toUpperCase().includes(search.toUpperCase())) {
            cList.push(c);
        }
        else if(c.nameLast && c.nameLast.toUpperCase().includes(search.toUpperCase())) {
            cList.push(c);
        }
        else if(c.company && c.company.toUpperCase().includes(search.toUpperCase())) {
            cList.push(c);
        }
        else if (c.email && c.email.toUpperCase().includes(search.toUpperCase())) {
            cList.push(c);
        }
    });
    return cList;
}

export function floorLabel(floor: number | undefined) {
    if (floor === undefined) {
        return null;
    }
    if (floor < 0) {
        return "Niveau " + floor;
    }
    if (floor === 0) {
        return "Rez-de-chaussée";
    } else if (floor === 1) {
        return floor + "er étage";
    } else {
        return floor + "ème étage";
    }
}

export function convertDateFromString(date: string): string {
    date = date.replace(/\-/g, "");
    const year = date.substring(0, 4);
    const month = date.substring(4, 6);
    const day = date.substring(6, 8);
    return new Intl.DateTimeFormat("fr-FR", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
    }).format(date ? Date.parse(year + "-" + month + "-" + day) : new Date());
}

export function convertStringDateToJsonDate(date: string): string {
    const value = new Intl.DateTimeFormat("fr-FR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    }).format(Date.parse(date)).split("/");
    return value[2] + '-' + value[1] + '-' + value[0]
}

export function convertStringDateToJsonTime(date: string): string {
    return new Intl.DateTimeFormat("fr-FR", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
    }).format(Date.parse(date));
}

export function convertToDateFromDateStringAndTimeString(date: string, time: string): Date {
    date = date.replace(/\-/g, "");
    const year = date.substring(0, 4);
    const month = date.substring(4, 6);
    const day = date.substring(6, 8);
    const dateString = year + "-" + month + "-" + day + "T"+ time;
    const newDate = new Date(dateString);
    return newDate;
}

export function convertDateToStringTime(date: Date | null): string {
    if(date != null) {
        return new Intl.DateTimeFormat("fr-FR", {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit"
        }).format(date);
    }
    return "";
}

export function convertDateToString(date: Date| null): string {
    if(date != null) {
        const value = new Intl.DateTimeFormat("fr-FR", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
        }).format(date).split("/");
        return value[2] + '-' + value[1] + '-' + value[0]
    }
    return "";
}




