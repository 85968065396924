import React, { useState, useEffect } from 'react';
import { useField } from 'formik';
import { SquareButton } from '../FAButtons';
import { PlusIcon, LessIcon } from '../Icons';
import './FANumberField.scss';

const FANumberField: React.FunctionComponent<any> = ({ label, displayHandler, backgroundColor, ...props }: any) => {
    const [field, meta, helper] = useField(props);
    const [number, setNumber] = useState(props.initial);

    useEffect(() => {
        if (field.value) {
            setNumber(field.value);
        }
    }, [field.value]);


    function incrementeValue() {
        let num = number;
        if (!num) num = 0;
        if (num < props.max) {
            helper.setValue(num + props.step);
        }
    }

    function decrementeValue() {
        let num = number;
        if (!num) num = props.min + props.step;
        if (num > props.min) {
            helper.setValue(num - props.step);
        }
    }

    const InputRender = () => {
        if (props.type && props.type === "hidden" && displayHandler) {
            return (
                <>
                    <input {...field} {...props} />
                    <input type="text" name="numberText" value={displayHandler(number)} readOnly />
                </>
            );
        } else {
            return (
                <>
                    <input {...field} {...props} readOnly />
                </>
            );
        }
    }

    return (
        <div className="fanumberfield">
            <label>{label}</label>
            <div>
                <SquareButton
                    size='big'
                    color={backgroundColor}
                    className='left'
                    icon={<LessIcon color="orange" size="big" />}
                    handleClick={() => { decrementeValue() }}
                />
                <InputRender />
                <SquareButton
                    size='big'
                    color={backgroundColor}
                    className='right'
                    icon={<PlusIcon color="orange" size="big" />}
                    handleClick={() => { incrementeValue() }}
                />
            </div>
            {meta.touched && meta.error ? (<div className="error">{meta.error}</div>) : null}
        </div>
    );
}

export default FANumberField;
