import {convertStringDateToJsonDate, convertStringDateToJsonTime, currentToken} from "../components/Utils"
import {AttendeeData, ReservationFormData} from '../components/Types'

// #########################################################
/**
 *
 */
export function meetingList() {
  const token = currentToken();
  //
  const today = new Date();
  const fromDateStr = today.getFullYear() + '-'
      + (today.getMonth() + 1).toString().padStart(2, '0') + '-'
      + (today.getDate()).toString().padStart(2, '0') ;
  today.setDate(today.getDate()+30);
  //
  const options:RequestInit = {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token: token,
      fromDate: fromDateStr
    })
  }
  return fetch(`${process.env.REACT_APP_PUBLIC_URL}/booking/102/reservation/fetch/all`, options).then(async (r) => {
    if(r.status !== 200) throw "http:"+r.status
    let json = await r.json()
    if(json.header.status === 0) throw json.header.error;
    return json;
  });     
}

// #########################################################
/**
 *
 * @param meeting
 */
export async function createMeeting(data: ReservationFormData) {
  const attendees:AttendeeData[] = []
  data.reservation.attendees?.map(a => {
    attendees.push({ref: a.code});
  })
  const reservation = {
    name: data.reservation.name,
    day: convertStringDateToJsonDate(data.reservation.day),
    room: {
      code: data.reservation.room.code
    },
    fromTime: convertStringDateToJsonTime(data.reservation.fromTime),
    toTime: convertStringDateToJsonTime(data.reservation.toTime),
    attendees: attendees,
    "service": 100
  }

  const options:RequestInit = {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token: currentToken(),
      reservation:reservation
    })
  }
  return fetch(`${process.env.REACT_APP_PUBLIC_URL}/booking/102/reservation/add`, options).then(async (r) => {
    if(r.status !== 200) throw "http:"+r.status;
    let json = await r.json()
    if(json.header.status === 0) throw json.header.error;
    return json;
  });
}

// #########################################################
/**
 *
 * @param code
 */
export async function deleteMeeting(code : string) {
  const reservation = {
    code: code,
  }
  const options:RequestInit = {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token: currentToken(),
      reservation
    })
  }
  return await fetch(`${process.env.REACT_APP_PUBLIC_URL}/booking/102/reservation/cancel`, options).then(async (r) => {
    if(r.status !== 200) throw "http:"+r.status;
    let json = await r.json()
    if(json.header.status === 0) throw json.header.error;
    return json;
  });
}
