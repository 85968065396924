const translations = {
  english: {
  },
  french: {
    codes: {
      err3018: 'Ce contact est référencé dans une réservation existante. Veuillez réessayer ultérieurement ou le supprimer de vos réservations',
      err3019: 'Un contact avec la même adresse email existe déjà',
      err5069: 'Une réservation passée ou en cours ne peut être supprimée'
    },
    common: {
      captcha:'Le captcha n\'a pas été résolu',
      my_meetings: 'Toutes mes réservations',
      attendees_number_label: 'participants',
      attendee_number_label: 'participant',
      people_plur_number_label: 'personnes',
      people_number_label: 'personne',
      email:'Votre adresse email',
      back:"Retour",
      unavailable:'Fonction non disponible. Veuillez contact le support technique à l\'adresse flexaccess@nexity.fr',
      email_placeholder:'adresse@domain.com',
      nav_bar: {
        meetings: 'Mes réservations',
        contacts: 'Contacts',
        new_meeting: 'Nouvelle réservation',
        badge:'BADGE',
        badge_title:'Badge Permanent',
        badge_title_info:'(ne peut être utilisé pour les réservations)'
      },
      verify_failed:'La vérification d\'identité à échouée',
      cancel: 'Annuler',
      confirm: 'Confirmer',
      close: 'Fermer',
      information: 'Information',
      confirmation: 'Confirmation',
      search_placeholder: 'Paul H / Nexity / paul.hauchon@ne...',
      new_contact_button: 'Créer un nouveau contact',
      error: 'Une erreur est survenue. Veuillez réessayer ou contacter un administrateur.',
      toast: {
        success_title: 'Confirmation',
        error_title: 'Erreur',
        processed_message: 'Votre demande a été traitée',
        error_message: "Votre demande n'a pas pu être traitée",
      }
    },
    login_screen: {
      login_label: 'Adresse email',
      login_placeholder: 'login',
      password_label: 'Mot de passe',
      password_placeholder: 'password',
      connexion_button: 'Se connecter',
      connexion_error: 'Authentification échouée',
      email_error: 'Email invalide',
      password_length_error: '6 caractères minimum',
      register: 'Pas encore inscrit ?',
      forgot_password: 'Mot de passe oublié ?',
      contact_failed:'Récupération de votre liste de contact échouée'
    },
    register_screen: {
      title_succeed:'Confirmation d\'inscription',
      title_failed:'Inscription échouée',
      login: 'Déjà inscrit ? Connectez-vous',
      submit_button: 'S\'inscrire',
      back_button: 'Retour',
      cancel_button: 'Annuler',
      email_label: 'Adresse Email professionnelle',
      email_placeholder: 'email@nexity.com',
      code_label: 'Code d\'activation',
      code_placeholder: '1234-5678',
      namefirst_label:"Votre Prénom",
      namelast_label:"Votre Nom",
      password_label: 'Nouveau mot de passe',
      password_confirm_label: 'Confirmation du mot de passe',
      password_placeholder: 'password',
      success: 'Votre compte a été créé.',
      error:'Votre compte n\'a pas pu être créé',
      succeed: 'Votre compte a été créé avec succès. Un email vous a été envoyé avec votre mot de passe temporaire',
      explanation:'Pour réaliser votre inscription, vous devez préciser votre code d\'activation personnel ou celui de votre entreprise, ainsi que votre adresse email professionnelle. \n\nUne fois votre inscription validée, vous recevrez un email contenant votre mot de passe d\'accès temporaire.',
      confirmation_succeed_header:'Votre inscription a été réalisée avec succès. ',
      confirmation_succeed_detail:'Vous allez recevoir d\'ici quelques minutes, sur votre adresse %email%,  un email contenant votre mot de passe d\'accès temporaire.\n\nLors de votre première connexion, vous pourrez choisir un mot de passe personnel.',
      confirmation_succeed_footer:'L\'équipe FlexAccess vous souhaite un très bonne journée et vous remercie pour votre inscription.',
      confirmation_failed_header:'Votre inscription n\'a pas pu être finalisée: ',
      confirmation_failed_footer:'L\'équipe FlexAccess est désolée de cet incident et vous propose de relancer votre inscription.\n\nEn cas de difficulté à vous inscrire, vous pouvez contacter le service client à l\'adresse: ',
      confirmation_links:'Quelques liens utiles:',
      error_header:'Votre compte n\'a pas pu être créé.',
      errors: {
        invalid_code:'Le code d\'activation est invalide',
        invalid_company:'Votre entreprise ne peut pas accéder à ce service',
        already_registred:'Votre compte a déjà été créé. Si vous avez perdu votre mot de passe vous pouvez vous rendre sur la page de connexion et utiliser le lien de perte de mot de passe.',
        invalid_captcha:'Le système de protection Captcha n\'a pas été résolu',
        invalid_domain:'L\'adresse email saisie ne correspond pas à celle de votre entreprise',
      }

    },
    reset_screen: {
      title:"Perte de mot de passe",
      button: "Réinitialiser",
      email_label: 'Adresse email',
      explanation:'Saisissez l\'adresse e-mail associée à votre compte FlexAccess.\nUn email avec un lien de réinitialisation vous sera envoyé après validation de votre demande. Ce lien sera valable pour une durée de 15 minutes.',
      message_succeed:'Votre demande de réinitialisation a été prise en compte. Un email vient de vous être envoyé',
      message_failed:'Votre demande de réinitialisation a échouée. Veuillez vérifier votre adresse email ou contacter le support FlexAccess en cas de difficulté persistante.'
    },
    password_screen: {
      title:'Mot de Passe',
      button:'Mettre à jour',
      header: 'Veuillez saisir un mot de passe personnel fin de procéder à la mise à jour de votre compte.\n\nLe nouveau mot de passe doit contenir au minimum 6 caractères avec au moins un chiffre et une majuscule.',
      password_old:'Mot de passe actuel',
      password_new:'Nouveau mot de passe',
      password_confirm:'Confirmation du mot de passe',
      message_failed:'Modification du mot de passe échouée: ',
      message_succeed:'Mot de passe modifié avec succès',
    },
    home_screen: {
      future_meetings_number_label: 'rendez-vous à venir',
      new_meeting_button: 'Nouvelle réservation',
      cancel_meeting: 'Êtes-vous sûr de vouloir annuler cette réservation\u00A0?',
      disconnect:"Êtes-vous sûr de vouloir vous déconnecter\u00A0?",
    },
    meeting_detail: {
      cancel_button: 'Annuler la réservation',
      builing_label: 'Bâtiment',
      meeting_fetch_error:'Impossible de récupérer la liste de vos reservations.'
    },
    contacts_screen: {
      contact_number_label: 'contacts',
      contacts_number_label: 'contacts',
      search_fail: 'Aucun contact trouvé',
      fetch_failed:'Impossible de récupérer votre liste de contacts',
      delete_confirmation: 'Êtes-vous sûr de vouloir supprimer ce contact\u00A0?',
      create_error:'Creation du contact échouée',
      edit_error:'Mise à du contact échouée',
      delete_error:'Suppression du contact échouée',
      create_succeed:'Le contact a été créé avec succès',
      edit_succeed:'Le contact a été modifié avec succès',
      delete_succeed:'Le contact a été supprimé avec succès',
      form: {
        title: 'Ajouter un contact',
        firstname_label: 'Prénom',
        firstname_placeholder: 'Pierre',
        lastname_label: 'Nom',
        lastname_placeholder: 'Dumont',
        email_label: 'Adresse e-mail',
        email_placeholder: 'pierre.dumont@nexity.fr',
        company_label: 'Société',
        company_placeholder: 'Nexity'
      }
    },
    new_meeting_form: {
      create_error:'Creation de la réservation échouée',
      delete_error:'Suppression de la réservation échouée',
      create_succeed:'La réservation a été créée avec succès',
      delete_succeed:'La réservation a été supprimée avec succès',
      step_titles: {
        global_info: 'Informations générales',
        room_search: 'Choisir une salle',
        invite_attendees: 'Inviter des participants',
        finalization: 'Finalisation de la réservation',
        searching_rooms:'Recherche des disponibilités...',
      },
      placeholders: {
        name: '',
        date: '',
        hour_start: '',
        hour_end: '',
        city_select: 'Choisir une ville',
        room_building_select: 'Choisir un bâtiment',
        room_floor_select: 'Choisir un étage',
        room_select: 'Choisir une salle',
        message_to_attendees: 'Votre message ici',
        duration: '2h'
      },
      errors: {
        mandatory_field: ' ',
        invalid_time: ' ',
        invalid_date: ' ',
        search_room_failed:'Récupération des salles disponibles échouée'
      },
      labels: {
        name: 'Libellé',
        date: 'Date',
        hour_start: 'Heure de début',
        hour_end: 'Heure de fin',
        city_select: 'Ville',
        room_building_select: 'Bâtiments',
        room_floor_select: 'Étage',
        room_select: 'Nom de la salle',
        duration: 'Durée',
      },
      actions: {
        room_search: 'Choisir une salle',
        room_confirm: 'Confirmation',
        attendees_confirm: 'Inviter des participants',
        attendees_number_confirm: 'Inviter',
        meeting_summary: 'Récapitulatif',
        show_attendees_only: 'Afficher uniquement les participants',
        checkbox_self_participation: 'Je participe à ce rendez-vous.',
        checkbox_access_codes: 'Je souhaite envoyer des codes d\'accès aux invités',
        checkbox_notification: 'Je souhaite être notifié(e) de l\'arrivée des invités',
        validate: 'Valider la réservation',
        more_criteria: 'Plus de critères',
        less_criteria: 'Moins de critères'
      },
      attendees_number_label: 'Participants',
      room_capacity_number_label: 'personnes',
      message_to_attendees: 'Message aux participants',
      message_to_attendees_description: 'Ce message sera envoyé à l\'ensemble des participants de la réunion.',
      rooms_number_found_label: 'salles trouvées',
      room_number_found_label: 'salle trouvée',
      no_room_found: 'Aucune salle trouvée pour ces critères'
    }
  }
}

function t(key: string): string {
  const label = key.split('.').reduce(function (value, index) {
    if (!value[index]) return ""
    return value[index];
  }, translations['french']);
  return `${label}`
}

export { translations, t }
