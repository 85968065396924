import './Password.scss';
import React, { useState} from "react";
import {Formik} from 'formik';
import * as Yup from 'yup';
import { FATextField } from "../common/form/FATextField";
import { BigButton } from "../common/FAButtons";
import { t } from '../../languages/Translations'
import { resetPassword } from "../../api/user";
import {useHistory} from "react-router-dom";
import {ForwardArrowIcon} from "../common/Icons";
import ReCAPTCHA from "react-google-recaptcha";
import { ResetData} from "../Types";

const initValues: ResetData = {email:"",  captcha:''};

function Reset({value:{ setLoading, displayToaster }}) {
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const history = useHistory();
    const [captcha, setCaptcha] = useState("");

    function onCaptcha(value) {
        setCaptcha(value);
    }
    function apply(data) {
        if(!captcha || captcha.length < 10) {
            setErrorMsg(t("common.captcha"))
            setError()
            return;
        }
        setIsError(false);
        setLoading(true);
        data.captcha = captcha;
        resetPassword(data)
            .then(() => {
                setLoading(false);
                displayToaster({ message: t("reset_screen.message_succeed"), error: false })
                window.location.href = "/"
            })
            .catch(e => {
                setLoading(false);
                setErrorMsg(t("reset_screen.message_failed"))
                setError()
            });
    }

    function setError() {
        setIsError(true);
        setTimeout(function () {
            setIsError(false);
        }, 5000)
    }

    return (
        <div className="content content-padding">
            <Formik
                initialValues={initValues}
                onSubmit={apply}
                validationSchema={Yup.object().shape({
                    email: Yup.string().matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, " ").required(" "),
                })}
            >
                {formik => (
                    <form onSubmit={formik.handleSubmit}>
                        <div className="title-container">
                            <h2>{t("reset_screen.title")}</h2>
                        </div>
                        <p>&nbsp;</p>
                        <p><span className="text-ellipsis">{t("reset_screen.explanation")}</span></p>
                        <p>&nbsp;</p>
                        <div className="text-error">{isError && <span>{errorMsg}</span>}</div>
                            <div>
                                <FATextField
                                    label={t('reset_screen.email_label')}
                                    name="email"
                                    type="text"
                                    autocomplete={true}
                                />
                                <ReCAPTCHA
                                    sitekey="6LfDitkZAAAAADt6HjXIV19rGmEFe_pqr-ZTYjLF"
                                    onChange={onCaptcha}
                                />
                                <p>&nbsp;</p>
                                <BigButton type="submit" label={t('reset_screen.button')} icon={<ForwardArrowIcon value="white" />} color="orange" size="big" handleClick={() => { }} />
                            </div>
                    </form>
                )}
            </Formik>
        </div>
    );
}

export { Reset };
