import React from "react";
import { FormikValues, useFormikContext} from 'formik';
import './Register.scss';
import '../meeting/MeetingDetails.scss';
import { FATextField } from "../common/form/FATextField";
import { t } from '../../languages/Translations'
import ReCAPTCHA from "react-google-recaptcha";
import {FATextField2} from "../common/form/FATextField2";

const RegisterStepForm: React.FunctionComponent<FormikValues> = (props) => {

    const formik: any = useFormikContext();

    function onCaptcha(value) {
        formik.values.captcha = value;
    }

    function updateCodeA(component: React.ChangeEvent<HTMLInputElement>) {
        formik.setFieldValue('codeA', component.target.value);
        if (component.target.value && component.target.value.length === 4 && component.target.value.match(/^9[0-9]{3}$/)) { // @ts-ignore
            document.getElementById("codeB").focus()
        }
    }

    function updateCodeB(component: React.ChangeEvent<HTMLInputElement>) {
        formik.setFieldValue('codeB', component.target.value);
        if (component.target.value && component.target.value.length === 4 && component.target.value.match(/^[0-9]{4}$/)) { // @ts-ignore
            document.getElementById("email").focus()
        }
    }

    return (
        <div className="meeting-content limited-width">
            <div>
                <p><span className="text-ellipsis">{t("register_screen.explanation")}</span></p>
                <p>&nbsp;</p>
            </div>
            <div>
                <div className="meeting-flex">
                    <div>
                        <FATextField2
                            label={t("register_screen.code_label")}
                            name="codeA"
                            placeholder="9000"
                            required={true}
                            handleChange={updateCodeA}
                            maxLength={4}
                            id={"codeA"}
                            autofocus={true}
                        />
                    </div>
                    <div className="code-picker center">
                        <div className="separator" />
                    </div>
                    <div>
                        <FATextField2
                            name="codeB"
                            placeholder="0000"
                            maxLength={4}
                            handleChange={updateCodeB}
                            id={"codeB"}
                        />
                    </div>
                </div>
                <FATextField
                    label={t("register_screen.email_label")}
                    name="email"
                    placeholder="xyz@domaine.fr"
                    required={true}
                    id={"email"}
                />
                <FATextField
                    label={t("register_screen.namelast_label")}
                    name="lastname"
                    placeholder=""
                    required={false}
                />
                <FATextField
                    label={t("register_screen.namefirst_label")}
                    name="firstname"
                    placeholder=""
                    required={false}
                />
                <ReCAPTCHA
                    sitekey="6LfDitkZAAAAADt6HjXIV19rGmEFe_pqr-ZTYjLF"
                    onChange={onCaptcha}
                />
            </div>
        </div>
    );
}
export { RegisterStepForm };
